import React, { useState, useEffect, useRef, useMemo } from 'react';

// LoadPanel
import { LoadPanel } from 'devextreme-react/load-panel';

// Toast
import { Toast } from 'devextreme-react/toast';

// Grid
import DataGrid, {
  Column, FilterRow, HeaderFilter, FilterPanel, FilterBuilderPopup,
  GroupPanel, Grouping, SearchPanel, Sorting, Editing, Lookup, Toolbar, Item, RequiredRule,
  Scrolling, Pager, Paging, Summary, TotalItem, StateStoring, ColumnChooser, Export,
  ColumnChooserSearch, ColumnChooserSelection
} from 'devextreme-react/data-grid';

//CheckBox
import CheckBox from 'devextreme-react/check-box';

//TagBox
import TagBox from "devextreme/ui/tag_box";

//Form
import Form, { GroupItem, Label, TabbedItem, TabPanelOptions, Tab } from 'devextreme-react/form';

import { Auth, API, Storage } from 'aws-amplify';

// Ruteador
import { Route, Link, useHistory } from "react-router-dom";

// Iconos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/pro-solid-svg-icons";
import { faArrowRotateLeft } from "@fortawesome/pro-solid-svg-icons";
import { faSave } from "@fortawesome/pro-solid-svg-icons";
import { faList } from "@fortawesome/pro-duotone-svg-icons";
import { faUser } from "@fortawesome/pro-duotone-svg-icons";
import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons";

import './Users.css';

import DataSource from 'devextreme/data/data_source';

import '../ListStyle.css';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter'

export default function Users(props) {
  const history = useHistory();

  const [isDetail, setIsDetail] = useState(false);
  const [userName, setUserName] = useState();
  const [userRecords, setUserRecords] = useState([]);
  const [employees, setEmployees] = useState([]);

  const [userPayload, setUserPayload] = useState({
  });

  const [autoExpandAll, setAutoExpandAll] = useState(true);

  //Controles para expander los grupos del grid de checklist
  const [autoExpandAllChecklist, setAutoExpandAllChecklist] = useState(true);

  const [visibleLoadPanel, setVisibleLoadPanel] = useState(false);

  const [visibleToast, setVisibleToast] = useState(false);
  const [messageToast, setMessageToast] = useState('');
  const [typeToast, setTypeToast] = useState('success');

  const [ok, setOk] = useState(false);

  function getOrderDay(rowData) {
    return (new Date(rowData.OrderDate)).getDay();
  };

  const filterBuilder = useMemo(() => {
    return {
      customOperations: [{
        name: 'weekends',
        caption: 'Weekends',
        dataTypes: ['date'],
        icon: 'check',
        hasValue: false,
        calculateFilterExpression: () => [[getOrderDay, '=', 0], 'or', [getOrderDay, '=', 6]],
      }],
      allowHierarchicalFields: true,
    };
  }, []);

  const filterBuilderPopupPosition = useMemo(() => {
    return {
      of: window,
      at: 'top',
      my: 'top',
      offset: { y: 10 },
    };
  }, []);

  const allowedPageSizes = useMemo(() => {
    return [5, 10, 15, 20, 'all'];
  }, []);

  const exportFormats = useMemo(() => {
    return ['xlsx'];
  }, []);

  const getEmployeeList = React.useCallback(async () => {
    let apiName = 'portalvinoteca';
    let path = '/procesahelper';

    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      },
      body: {
        action: 'employeeList',
        q: window.btoa(JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username)
      }
    }

    var employeesGet = await API.post(apiName, path, myInit);

    setEmployees(employeesGet.data);

  }, []);

  const getUsersList = React.useCallback(async () => {
    // Obtenemos los usuarios de Cognito
    let apiNameCognito = 'AdminQueries';
    let pathCognito = '/listUsers';

    let myInitCognito = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
    }

    const { NextToken, ...restCognito } = await API.get(apiNameCognito, pathCognito, myInitCognito);

    var users = [];

    // Obtenemos los atributos de los usuarios desde Netsuite
    let apiName = 'portalvinoteca';
    let path = '/procesausuario';

    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      },
      body: {
        action: 'list',
        q: window.btoa(JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username)
      }
    }

    var usersGet = await API.post(apiName, path, myInit);

    for (var singleUserIndex in restCognito.Users) {
      var singleUser = restCognito.Users[singleUserIndex];

      var addUser = {
        Username: singleUser.Username,
        Enabled: singleUser.Enabled,
        isAdmin: singleUser.isAdmin
      };

      if (singleUser.Enabled)
        addUser.Enabled = 'Activo'
      else
        addUser.Enabled = 'Inactivo'

      if (singleUser.isAdmin)
        addUser.isAdmin = 'Admin'
      else
        addUser.isAdmin = ''

      // Agregamos el email
      for (var attributeIndex in singleUser.Attributes) {
        var singleAttribute = singleUser.Attributes[attributeIndex];

        if (singleAttribute.Name === 'email')
          addUser.Email = singleAttribute.Value;
      }

      // Agregamos el resto de los atributos
      for (var usersGetIndex in usersGet.data) {
        var singleUserGet = usersGet.data[usersGetIndex];

        if (singleUserGet.userName === singleUser.Username) {
          addUser.internalid = singleUserGet.internalid;
          addUser.cluster = singleUserGet.cluster;
          addUser.clusterLevel = singleUserGet.clusterLevel;
          addUser.location = singleUserGet.location;
          addUser.locationLevel = singleUserGet.locationLevel;
          addUser.clusterType = singleUserGet.clusterType;
          addUser.clusterType = singleUserGet.clusterTypeLevel;
          addUser.productCluster = singleUserGet.productCluster;
          addUser.productClusterLevel = singleUserGet.productClusterLevel;
          addUser.custrecord_al_portal_employee = singleUserGet.custrecord_al_portal_employee;

        }
      }
      users.push(addUser);
    }

    setUserRecords(users);
  }, []);

  const getUserPayload = React.useCallback(async (Username, internalid) => {
    try {
      // Obtenemos los usuarios de Cognito
      let apiNameCognito = 'AdminQueries';
      let pathCognito = '/getUser';

      let myInitCognito = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        queryStringParameters: {
          username: Username,
          q: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
        }
      }

      var userGetCognito = await API.get(apiNameCognito, pathCognito, myInitCognito);

      var user = {
        Username: userGetCognito.Username,
        Enabled: userGetCognito.Enabled,
        isAdmin: ''
      };

      // Obtenemos los atributos de los usuarios desde Netsuite
      let apiName = 'portalvinoteca';
      let path = '/procesausuario';

      let myInit = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        body: {
          action: 'payload',
          userName: Username,
          internalid: internalid,
          q: window.btoa(JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username)
        }
      }

      var userAttributes = await API.post(apiName, path, myInit);

      user.internalid = userAttributes.data.internalid;

      // Asignamos los atributos
      if (!userAttributes.data.custrecord_al_portal_employee)
        user.custrecord_al_portal_employee = ''
      else
        user.custrecord_al_portal_employee = userAttributes.data.custrecord_al_portal_employee;

      if (!userAttributes.data.cluster)
        user.cluster = false
      else
        user.cluster = userAttributes.data.cluster;

      user.clusterLevel = userAttributes.data.clusterLevel;

      if (!userAttributes.data.location)
        user.location = false
      else
        user.location = userAttributes.data.location;

      user.locationLevel = userAttributes.data.locationLevel;

      if (!userAttributes.data.clusterType)
        user.clusterType = false
      else
        user.clusterType = userAttributes.data.clusterType;

      user.clusterTypeLevel = userAttributes.data.clusterTypeLevel;

      if (!userAttributes.data.productCluster)
        user.productCluster = false
      else
        user.productCluster = userAttributes.data.productCluster;

      user.productClusterLevel = userAttributes.data.productClusterLevel;

      if (user.Enabled)
        user.Enabled = 'Activo'
      else
        user.Enabled = 'Inactivo'

      for (var attributeIndex in userGetCognito.UserAttributes) {
        var singleAttribute = userGetCognito.UserAttributes[attributeIndex];

        if (singleAttribute.Name === 'email')
          user.Email = singleAttribute.Value;
      }

      let apiNameGroups = 'AdminQueries';
      let pathGroups = '/listGroupsForUser';

      let myInitGroups = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        queryStringParameters: {
          username: Username,
          q: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
        }
      }

      var userGroupsGet = await API.get(apiNameGroups, pathGroups, myInitGroups);

      for (var groupIndex in userGroupsGet.Groups) {
        var singleGroup = userGroupsGet.Groups[groupIndex];

        user.isAdmin = singleGroup.GroupName;
      }
    }
    catch (e) {
      if (e === 'No current user') {
        history.push('/');

        window.location.reload();
      }
    }

    return user;
  }, [history]);

  const editUserButton = React.useCallback(async (Username, internalid) => {
    if (!(window.event && (window.event.metaKey || window.event.ctrlKey))) {
      setVisibleLoadPanel(true);

      setIsDetail(true);

      setUserName(Username);

      var payload = await getUserPayload(Username, internalid);

      setUserPayload(payload);

      setVisibleLoadPanel(false);
    }
  }, [getUserPayload]);

  const cancelEditButton = React.useCallback(async () => {
    setVisibleLoadPanel(true);

    setIsDetail(false);

    await getUsersList();

    setVisibleLoadPanel(false);

    history.push('/Users');
  }, [getUsersList, history]);

  const deleteUser = React.useCallback(async () => {
    try {
      var username = userPayload.Username;

      if (username) {
        var r = window.confirm('Se eliminará el usuario ' + username + '. ¿Desea Continuar?');

        if (r) {
          let apiNameCognito = 'AdminQueries';
          let pathCognito = '/deleteUser';

          let myInitCognito = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            body: {
              username: username,
              q: window.btoa(JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username)
            }
          }

          try {
            setVisibleLoadPanel(true);

            await API.post(apiNameCognito, pathCognito, myInitCognito);

            setVisibleToast(true);
            setMessageToast('Usuario eliminado con éxito');
            setTypeToast('success')

            setIsDetail(false);

            await getUsersList();

            setVisibleLoadPanel(false);

            history.push('/Users');

          }
          catch (error) {
            setVisibleLoadPanel(false);

            setVisibleToast(true);
            setMessageToast(error.message);
            setTypeToast('error')
          }
        }
      }
    }
    catch (e) {
      if (e === 'No current user') {
        history.push('/');

        window.location.reload();
      }
    }
  }, [getUsersList, history, userPayload.Username]);

  const saveButton = React.useCallback(async () => {
    try {
      var username = userPayload.Username;
      var internalid = userPayload.internalid;
      var password = userPayload.Password;
      var confirmpassword = userPayload.ConfirmPassword;
      var group = userPayload.isAdmin;
      var sucursal = userPayload.Sucursal;
      var empleado = userPayload.custrecord_al_portal_employee;
      var email = userPayload.Email;
      var isenabled = userPayload.Enabled;

      if (username) {
        if (!password)
          password = '';
        if (!confirmpassword)
          confirmpassword = '';
        if (!group)
          group = '';

        if (!sucursal && sucursal !== 0)
          sucursal = '';

        if (password) {
          if (password.length < 8) {
            setVisibleToast(true);
            setMessageToast('La contraseña debe tener mínimo 10 caracteres');
            setTypeToast('error')
            return;
          }
          if (password !== confirmpassword) {
            setVisibleToast(true);
            setMessageToast('Las contraseñas no coinciden');
            setTypeToast('error')
            return;
          }
        }

        // Actualizamos los datos en Cognito
        let apiNameCognito = 'AdminQueries';
        let pathCognito = '/updateUser';

        let myInitCognito = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
          },
          body: {
            email: email,
            username: username,
            password: password,
            confirmpassword: confirmpassword,
            isenabled: isenabled,
            group: group,
            custrecord_al_portal_employee: empleado
          }
        }

        // Actualizamos los datos en Netsuite
        let apiName = 'portalvinoteca';
        let path = '/procesausuario';

        let myInit = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
          },
          body: {
            userName: username,
            internalid: internalid,
            action: 'postData',
            data: userPayload,
            q: window.btoa(JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username)
          }

        }

        try {
          setVisibleLoadPanel(true);

          await API.post(apiNameCognito, pathCognito, myInitCognito);

          await API.post(apiName, path, myInit);

          setVisibleLoadPanel(false);

          setVisibleToast(true);
          setMessageToast('Usuario actualizado con éxito');
          setTypeToast('success')
        }
        catch (error) {
          setVisibleLoadPanel(false);

          setVisibleToast(true);
          setMessageToast(error.message);
          setTypeToast('error')
        }
      }
    }
    catch (e) {
      if (e === 'No current user') {
        history.push('/');

        window.location.reload();
      }
    }
  }, [history, userPayload]);

  const onFieldDataChanged = React.useCallback((e) => {
  }, []);

  const editUser = React.useCallback((data) => {
    return (
      <Link to={`/Users/${data.data.Username}`}>
        <button class="bg-blue-500 hover:bg-blue-700 text-white  py-1 px-3 rounded" onClick={() => editUserButton(data.data.Username, data.data.internalid)}><FontAwesomeIcon icon={faPencilAlt} /> Editar</button>
      </Link>
    )
  }, [editUserButton])

  const userEnabled = React.useCallback((data) => {
    return (
      <span className={data.data.Enabled === "Activo" ? "px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-sm" : "px-2 py-1 font-semibold leading-tight text-red-700 bg-red-100 rounded-sm"}> {data.data.Enabled} </span>
    )
  }, []);

  const userAdmin = React.useCallback((data) => {
    return (
      <span className={data.data.isAdmin === "Admin" ? "px-2 py-1 font-semibold leading-tight text-pink-700 bg-pink-100 rounded-sm}" : ""} > {data.data.isAdmin === "Admin" ? "Si" : ""}</span>
    )
  }, []);

  const onAutoExpandAllChanged = React.useCallback(() => {
    setAutoExpandAll(!autoExpandAll)
  }, [autoExpandAll]);

  const onAutoExpandAllChecklistChanged = React.useCallback(() => {
    setAutoExpandAllChecklist(!autoExpandAllChecklist)
  }, [autoExpandAllChecklist]);

  var internalidEditorOptions = useMemo(() => {
    return { width: '100%', readOnly: true, visible: false };
  }, []);

  var userNameEditorOptions = useMemo(() => {
    return { width: '100%', readOnly: true };
  }, []);

  var emailEditorOptions = useMemo(() => {
    return { width: '100%', readOnly: true };
  }, []);

  var passwordEditorOptions = useMemo(() => {
    return { width: '100%', disabled: false, mode: 'password' };
  }, []);

  //Opciones Grupo del Usuario
  const isAdminOptions = useMemo(() => {
    return [
      { value: '', name: '' },
      { value: 'Admin', name: 'Admin' }
    ];
  }, []);

  try {
    var isAdminEditorOptions = useMemo(() => {
      return {
        items: isAdminOptions,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: true,
        value: (userPayload.isAdmin ? userPayload.isAdmin : ''), width: '100%'
      };
    }, [isAdminOptions, userPayload.isAdmin]);
  }
  catch (e) {
    var isAdminEditorOptions = {
      items: isAdminOptions,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: true,
      value: '',
      width: '100%'
    };
  }

  //Opciones Estatus del Usuario
  const enabledOptions = useMemo(() => {
    return [
      { value: 'Activo', name: 'Activo' },
      { value: 'Inactivo', name: 'Inactivo' }
    ];
  }, []);

  try {
    var enabledEditorOptions = useMemo(() => {
      return {
        items: enabledOptions,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: true,
        value: (userPayload.Enabled ? userPayload.Enabled : ''), width: '100%'
      };
    }, [enabledOptions, userPayload.Enabled]);
  }
  catch (e) {
    var enabledEditorOptions = {
      items: enabledOptions,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: true,
      value: '',
      width: '100%'
    };
  }

  //Opciones Nivel de Permiso
  const levelOptions = useMemo(() => {
    return [
      { value: 'Ver', name: 'Ver' },
      { value: 'Editar', name: 'Editar' }
    ];
  }, []);

  //Opciones Nivel de Permiso Product Cluster
  const productClusterlevelOptions = useMemo(() => {
    return [
      { value: 'Ver', name: 'Ver' },
      { value: 'Editar', name: 'Editar' },
      { value: 'Procesar', name: 'Procesar' }
    ];
  }, []);

  try {
    var clusterLevelEditorOptions = useMemo(() => {
      return {
        items: levelOptions,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: false,
        value: (userPayload.clusterLevel ? userPayload.clusterLevel : ''), width: '50%', disabled: false
      };
    }, [levelOptions, userPayload.clusterLevel]);

  }
  catch (e) {
    var clusterLevelEditorOptions = {
      items: levelOptions,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: false,
      value: '',
      width: '50%'
    };
  }

  try {
    var employeeEditorOptions = useMemo(() => {
      return {
        items: employees,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: true,
        value: (userPayload.custrecord_al_portal_employee ? userPayload.custrecord_al_portal_employee : ''),
        width: '100%',
        disabled: false
      };
    }, [employees, userPayload.custrecord_al_portal_employee]);

  }
  catch (e) {
    var employeeEditorOptions = {
      items: employees,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: true,
      value: '',
      width: '100%'
    };
  }


  try {
    var locationLevelEditorOptions = useMemo(() => {
      return {
        items: levelOptions,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: false,
        value: (userPayload.locationLevel ? userPayload.locationLevel : ''), width: '50%', disabled: false
      };
    }, [levelOptions, userPayload.locationLevel]);
  } catch (error) {
    var locationLevelEditorOptions = {
      items: levelOptions,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: false,
      value: '',
      width: '50%'
    };
  }

  try {
    var clusterTypeLevelEditorOptions = useMemo(() => {
      return {
        items: levelOptions,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: false,
        value: (userPayload.clusterTypeLevel ? userPayload.clusterTypeLevel : ''), width: '50%', disabled: false
      };
    }, [levelOptions, userPayload.clusterTypeLevel]);
  } catch (error) {
    var clusterTypeLevelEditorOptions = {
      items: levelOptions,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: false,
      value: '',
      width: '50%'
    };
  }


  try {
    var productClusterLevelEditorOptions = useMemo(() => {
      return {
        items: productClusterlevelOptions,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: false,
        value: (userPayload.productClusterLevel ? userPayload.productClusterLevel : ''), width: '50%', disabled: false
      };
    }, [productClusterlevelOptions, userPayload.productClusterLevel]);
  } catch (error) {
    var productClusterLevelEditorOptions = {
      items: productClusterlevelOptions,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: false,
      value: '',
      width: '50%'
    };
  }
  var toastPosition = useMemo(() => {
    return {
      my: 'top center',
      at: 'top center',
      of: window,
      offset: '0 130'
    }
  }, []);

  useEffect(() => {
    async function initialize() {
      setVisibleLoadPanel(true);

      var user = await Auth.currentAuthenticatedUser();

      var groups = user.signInUserSession.accessToken.payload["cognito:groups"];

      var okAdmin = false;

      for (var groupIndex in groups) {
        var singleGroup = groups[groupIndex];

        if (singleGroup === 'Admin') {
          setOk(true);
          okAdmin = true;
        }
      }

      if (okAdmin === true) {
        await getUsersList();
        await getEmployeeList();
      }
      
      setVisibleLoadPanel(false);
    }

    initialize();

    // En caso de solicitar un ID especifico, redireccionamos a ese ID
    if (!props.match.isExact) {
      var urlID = props.location.pathname.replace('/Users/', '');

      editUserButton(urlID);
    }

    // eslint-disable-next-line
  }, []);

  const onHidingToast = React.useCallback(() => {
    setVisibleToast(false);
  }, []);

  function onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Datos');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Datos.xlsx');
      });
    });
    e.cancel = true;
  };
  
  return (
    <div>
      {isDetail === false ? (
        <div class="bg-gray-200 m-2">
          <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faList} size="lg" /> Usuarios</h1>
        </div>
      ) :
        (
          <div class="bg-gray-200 m-2">
            <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1 pl-3"><FontAwesomeIcon icon={faUser} size="xl" /> {userPayload.Username}</h1>
          </div>
        )}

      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        visible={visibleLoadPanel}
        showIndicator={true}
        message="Por favor espere..."
        shading={true}
        showPane={true}
        hideOnOutsideClick={false}
      />

      <Toast
        visible={visibleToast}
        message={messageToast}
        type={typeToast}
        onHiding={onHidingToast}
        displayTime={5000}
        width={'auto'}
        position={toastPosition}
      />

      <div class="bg-gray-200 m-2">
        {isDetail === false ? (
          <div class="bg-white p-3 shadow-xl">
            <div className="options mb-2">
              <div className="option">
                <CheckBox text="Expandir todos los grupos"
                  value={autoExpandAll}
                  onValueChanged={onAutoExpandAllChanged}>
                </CheckBox>
              </div>
            </div>
            <DataGrid
              id="gridContainer"
              columnsAutoWidth={true}
              filterBuilder={filterBuilder}
              dataSource={userRecords}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnResizingMode="widget"
              showBorders={true}
              rowAlternationEnabled={true}
              showRowLines={true}
              showColumnLines={true}
              onExporting={onExporting}
            >
              <Scrolling rowRenderingMode='virtual'></Scrolling>
              <Paging defaultPageSize={10} />
              <Pager
                visible={true}
                allowedPageSizes={allowedPageSizes}
                displayMode='compact'
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true} />
              <FilterRow visible={true} />
              <FilterPanel visible={true} />
              <FilterBuilderPopup position={filterBuilderPopupPosition} />

              <HeaderFilter visible={true} />

              <GroupPanel visible={true} />
              <SearchPanel visible={true} />
              <ColumnChooser
                enabled={true}
                mode='select'
              >
                <ColumnChooserSearch
                  enabled={true}
                />

                <ColumnChooserSelection
                  allowSelectAll={true}
                  selectByClick={true}
                  recursive={true}
                />
              </ColumnChooser>
              <Grouping autoExpandAll={autoExpandAll} />
              <Export enabled={true} />
              <StateStoring enabled={true} type="localStorage" storageKey="gridContainerListUsers" />

              <Column caption="" alignment="center" width={100} cellRender={editUser} />

              <Column caption="Usuario" dataField="Username" dataType="string" width={150} />
              <Column caption="Correo Electronico" dataField="Email" dataType="string" width={200} />

              <Column caption="Estatus" alignment="center" width={120} cellRender={userEnabled} />

              <Column caption="Administrador" alignment="center" width={200} cellRender={userAdmin} />
              <Column caption="Clusters" dataField="cluster" dataType="boolean" width={200} visible={false} />
              <Column caption="Nivel Clusters" dataField="clusterLevel" dataType="string" width={200} visible={false} />
              <Column caption="Location" dataField="location" dataType="boolean" width={200} visible={false} />
              <Column caption="Nivel Location" dataField="locationLevel" dataType="string" width={200} visible={false} />
              <Column caption="Product-Clusters" dataField="productCluster" dataType="boolean" width={200} visible={false} />
              <Column caption="Nivel Product-Clusters" dataField="productClusterLevel" dataType="string" width={200} visible={false} />
              <Column caption="Cluster Type" dataField="clusterType" dataType="boolean" width={200} visible={false} />
              <Column caption="Nivel Cluster Type" dataField="clusterTypeLevel" dataType="string" width={200} visible={false} />
              <Column caption="Empleado Netsuite" dataField="custrecord_al_portal_employee" dataType="string" width={250} visible={false} />

              <Column caption="internalid" dataField="internalid" dataType="number" width={200} visible={false} />

            </DataGrid>
          </div>
        ) : (<div />)}
        <Route exact path="/Users/:userName" render={({ match }) => {
          return (
            <div class="m-3 bg-gray-200">
              {isDetail.toString() === 'true' ?
                (
                  <div>
                    <Link to="/Users">
                      <button onClick={cancelEditButton} type="submit" class="mb-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                        <FontAwesomeIcon icon={faArrowRotateLeft} /> Regresar
                      </button>
                    </Link>

                    <button onClick={saveButton} type="submit" class="ml-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                      <FontAwesomeIcon icon={faSave} /> Guardar Cambios
                    </button>

                    <button onClick={deleteUser} type="submit" class="ml-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                      <FontAwesomeIcon icon={faTrashAlt} /> Eliminar Usuario
                    </button>

                    <div class="mt-1 bg-white pt-3 shadow-xl">
                      <div class="ml-4 mr-4 bg-white pt-3">
                        <Form
                          id="datosGenerales"
                          labelMode={'outside'}
                          formData={userPayload}

                          showColonAfterLabel={false}
                          labelLocation={'left'}
                          colCount={2}
                          onFieldDataChanged={onFieldDataChanged}
                        >
                          <GroupItem caption="Datos Generales">
                            <Item cssClass="boldText" dataField="internalid" editorOptions={internalidEditorOptions} >
                              <Label text="" visible={false} />
                            </Item>
                            <Item cssClass="boldText" dataField="Username" editorOptions={userNameEditorOptions} />
                            <Item cssClass="boldText" dataField="Email" editorOptions={emailEditorOptions} />
                            <Item dataField="Enabled" editorType="dxSelectBox" editorOptions={enabledEditorOptions} >
                              <Label text="Estatus" />
                            </Item>
                            <Item dataField="isAdmin" editorType="dxSelectBox" editorOptions={isAdminEditorOptions} >
                              <Label text="Grupo" />
                            </Item>
                            <Item dataField="custrecord_al_portal_employee" editorType="dxSelectBox" editorOptions={employeeEditorOptions}>
                              <Label text="Empleado Netsuite" />
                            </Item>
                          </GroupItem>
                          <GroupItem caption="Contraseña">
                            <Item dataField="Password" editorOptions={passwordEditorOptions} >
                              <Label text="Contraseña" />
                            </Item>
                            <Item dataField="ConfirmPassword" editorOptions={passwordEditorOptions} >
                              <Label text="Confirmar Contraseña" />
                            </Item>
                          </GroupItem>
                          <GroupItem caption="Accesos" colSpan={2}>
                          </GroupItem>
                          <GroupItem caption="Clusters">
                            <TabbedItem>
                              <TabPanelOptions deferRendering={false} showNavButtons={true} />
                              <Tab title="Cluster Types">
                                <Item dataField="clusterType" editorType="dxCheckBox">
                                  <Label text="Cluster Types" />
                                </Item>
                                <Item dataField="clusterTypeLevel" editorType="dxSelectBox" editorOptions={clusterTypeLevelEditorOptions} >
                                  <Label text="Nivel Permiso" />
                                </Item>
                              </Tab>
                              <Tab title="Clusters">
                                <Item dataField="cluster" editorType="dxCheckBox">
                                  <Label text="Clusters" />
                                </Item>
                                <Item dataField="clusterLevel" editorType="dxSelectBox" editorOptions={clusterLevelEditorOptions} >
                                  <Label text="Nivel Permiso" />
                                </Item>
                              </Tab>
                              <Tab title="Locations">
                                <Item dataField="location" editorType="dxCheckBox">
                                  <Label text="Locations" />
                                </Item>
                                <Item dataField="locationLevel" editorType="dxSelectBox" editorOptions={locationLevelEditorOptions} >
                                  <Label text="Nivel Permiso" />
                                </Item>
                              </Tab>
                              <Tab title="Product-Clusters">
                                <Item dataField="productCluster" editorType="dxCheckBox">
                                  <Label text="Product Clusters" />
                                </Item>
                                <Item dataField="productClusterLevel" editorType="dxSelectBox" editorOptions={productClusterLevelEditorOptions} >
                                  <Label text="Nivel Permiso" />
                                </Item>
                              </Tab>
                            </TabbedItem>
                          </GroupItem>
                        </Form>
                      </div>
                    </div>
                  </div>
                ) :
                (<div></div>)
              }
            </div>
          );
        }}
        />
      </div>
    </div>
  );
}