import React, { useState, useEffect, useRef } from 'react';

import secureLocalStorage from "react-secure-storage";

//react-router-dom
import {
  Route,
  NavLink,
  HashRouter
} from "react-router-dom";

//Importaciones para el menu
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// Modulos de Vinoteca
import Home from "./Home/Home";
import Signin from "./Signin/Signin.js";
import Signout from "./Signout/Signout.js";
import Signup from "./Signup/Signup.js";
import PasswordChange from "./PasswordChange/PasswordChange.js";
import Users from "./Users/Users.js";
import Locations from "./Locations/Locations.js";
import Clusters from "./Clusters/Clusters.js";
import ClusterType from "./ClusterType/ClusterType.js";
import ProductCluster from "./ProductCluster/ProductCluster.js";

import logo from './vinoteca.png';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/pro-solid-svg-icons";
import { faWrench } from "@fortawesome/pro-solid-svg-icons";
import { faUserPlus } from "@fortawesome/pro-solid-svg-icons";
import { faUsers } from "@fortawesome/pro-solid-svg-icons";
import { faCircleUser } from "@fortawesome/pro-solid-svg-icons";
import { faSignInAlt } from "@fortawesome/pro-solid-svg-icons";
import { faKey } from "@fortawesome/pro-solid-svg-icons";
import { faSignOutAlt } from "@fortawesome/pro-solid-svg-icons";
import { faChartNetwork } from "@fortawesome/pro-regular-svg-icons";
import { faBox } from "@fortawesome/pro-solid-svg-icons";
import { faLocationDot } from "@fortawesome/pro-solid-svg-icons";
import { faTag } from "@fortawesome/pro-solid-svg-icons";
import { faBoxOpenFull } from "@fortawesome/pro-solid-svg-icons";

import { Amplify } from 'aws-amplify';
import { Auth, API, Hub } from 'aws-amplify';
import awsconfig from './aws-exports';

import './Main.css';

import esMessages from "devextreme/localization/messages/es.json";

import { locale, loadMessages } from "devextreme/localization";

import config from "devextreme/core/config";

Amplify.configure(awsconfig);

export default function Main(props) {
  // MENU HOME
  const [anchorHome, setAnchorHome] = React.useState(null);
  const openHome = Boolean(anchorHome);

  function handleClickHome(event) {
    setAnchorHome(event.currentTarget);
  };
  function handleCloseHome() {
    setAnchorHome(null);
  };

  // MENU ACCOUNT
  const [anchorAccount, setAnchorAccount] = React.useState(null);
  const openAccount = Boolean(anchorAccount);

  function handleClickAccount(event) {
    setAnchorAccount(event.currentTarget);
  };
  function handleCloseAccount() {
    setAnchorAccount(null);
  };

  // MENU CONFIGURATION
  const [anchorConfiguration, setAnchorConfiguration] = React.useState(null);
  const openConfiguration = Boolean(anchorConfiguration);

  function handleClickConfiguration(event) {
    setAnchorConfiguration(event.currentTarget);
  };
  function handleCloseConfiguration() {
    setAnchorConfiguration(null);
  };

  // MENU CONFIGURATION
  const [anchorCluster, setAnchorCluster] = React.useState(null);
  const openCluster = Boolean(anchorCluster);

  function handleClickCluster(event) {
    setAnchorCluster(event.currentTarget);
  };
  function handleCloseCluster() {
    setAnchorCluster(null);
  };

  const [status, setStatus] = useState('signedout');
  const [username, setUsername] = useState('');
  const [isadmin, setIsadmin] = useState('false');
  const [cluster, setCluster] = useState('false');
  const [clusterLevel, setClusterLevel] = useState('');
  const [location, setLocation] = useState('false');
  const [locationLevel, setLocationLevel] = useState('');
  const [productCluster, setProductCluster] = useState('false');
  const [productClusterLevel, setProductClusterLevel] = useState('');

  const [clusterType, setClusterType] = useState('false');
  const [clusterTypeLevel, setClusterTypeLevel] = useState('');

  async function getUserAttributes(user, newStatus) {
    // Obtenemos los atributos de los usuarios desde Netsuite
    let apiName = 'portalvinoteca';
    let path = '/procesausuario';

    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      },
      body: {
        action: 'payload',
        userName: JSON.parse(JSON.stringify(user)).username,
        internalid: undefined,
        q: window.btoa(JSON.parse(JSON.stringify(user)).username)
      }
    }

    var userAttributes = await API.post(apiName, path, myInit);

    if (!userAttributes.data.internalid) {
      Signout();
      secureLocalStorage.clear();
      localStorage.clear();
      window.location.reload();
      return;
    }

    userAttributes.data.cluster = (userAttributes.data.cluster ? userAttributes.data.cluster : false);
    userAttributes.data.clusterType = (userAttributes.data.clusterType ? userAttributes.data.clusterType : false);
    userAttributes.data.productCluster = (userAttributes.data.productCluster ? userAttributes.data.productCluster : false);
    userAttributes.data.location = (userAttributes.data.location ? userAttributes.data.location : false);

    userAttributes.data.clusterLevel = (userAttributes.data.clusterLevel ? userAttributes.data.clusterLevel : 'Ver');
    userAttributes.data.clusterTypeLevel = (userAttributes.data.clusterTypeLevel ? userAttributes.data.clusterTypeLevel : 'Ver');
    userAttributes.data.productClusterLevel = (userAttributes.data.productClusterLevel ? userAttributes.data.productClusterLevel : 'Ver');
    userAttributes.data.locationLevel = (userAttributes.data.locationLevel ? userAttributes.data.locationLevel : 'Ver');

    setUsername(user.username);
    secureLocalStorage.setItem('username', user.username);

    setStatus(newStatus);
    secureLocalStorage.setItem('status', newStatus);

    setClusterType(userAttributes.data.clusterType);
    secureLocalStorage.setItem('clusterType', userAttributes.data.clusterType);

    setClusterTypeLevel(userAttributes.data.clusterTypeLevel);
    secureLocalStorage.setItem('clusterTypeLevel', userAttributes.data.clusterTypeLevel);

    setCluster(userAttributes.data.cluster);
    secureLocalStorage.setItem('cluster', userAttributes.data.cluster);

    setClusterLevel(userAttributes.data.clusterLevel);
    secureLocalStorage.setItem('clusterLevel', userAttributes.data.clusterLevel);

    setProductCluster(userAttributes.data.productCluster);
    secureLocalStorage.setItem('productCluster', userAttributes.data.productCluster);

    setProductClusterLevel(userAttributes.data.productClusterLevel);
    secureLocalStorage.setItem('productClusterLevel', userAttributes.data.productClusterLevel);

    setLocation(userAttributes.data.location);
    secureLocalStorage.setItem('location', userAttributes.data.location);

    setLocationLevel(userAttributes.data.locationLevel);
    secureLocalStorage.setItem('locationLevel', userAttributes.data.locationLevel);

    var groups = user.signInUserSession.accessToken.payload["cognito:groups"];

    for (var groupIndex in groups) {
      var singleGroup = groups[groupIndex];

      if (singleGroup === 'Admin') {
        setIsadmin('true');
        secureLocalStorage.setItem('isadmin', 'true');
      }
    }
  }

  const handleAuthStateChange = React.useCallback(async (data) => {
    var newStatus;

    if (data.payload.event === 'signIn')
      newStatus = 'signedin';
    else if (data.payload.event === 'signOut')
      newStatus = 'signedout';

    if (newStatus !== 'signedin' && newStatus !== 'signedout')
      return;

    if (newStatus === 'signedin' || newStatus === 'signedout') {
      if (newStatus === 'signedin') {
        Auth.currentAuthenticatedUser({
          bypassCache: false
        })
          .then(user => {
            getUserAttributes(user, newStatus);
          })
          .catch(err => {
            setStatus(newStatus);
            setUsername('');
            setIsadmin('false');
            setClusterType(false);
            setClusterTypeLevel('');
            setCluster(false);
            setClusterLevel('');
            setLocation(false);
            setLocationLevel('');
            setProductCluster(false);
            setProductClusterLevel('');


            secureLocalStorage.setItem('username', '');
            secureLocalStorage.setItem('status', newStatus);
            secureLocalStorage.setItem('isadmin', 'false');
            secureLocalStorage.setItem('clusterType', false);
            secureLocalStorage.setItem('clusterTypeLevel', '');
            secureLocalStorage.setItem('cluster', false);
            secureLocalStorage.setItem('clusterLevel', '');
            secureLocalStorage.setItem('location', false);
            secureLocalStorage.setItem('locationLevel', '');
            secureLocalStorage.setItem('productCluster', false);
            secureLocalStorage.setItem('productClusterLevel', '');

            secureLocalStorage.clear();
            localStorage.clear();
          });
      }
      else if (newStatus === 'signedout') {
        setStatus(newStatus);
        setUsername('');
        setIsadmin('false');
        setCluster(false);
        setClusterLevel('');
        setLocation(false);
        setLocationLevel('');
        setProductCluster(false);
        setProductClusterLevel('');

        secureLocalStorage.setItem('username', '');
        secureLocalStorage.setItem('status', newStatus);
        secureLocalStorage.setItem('isadmin', 'false');
        secureLocalStorage.setItem('clusterType', false);
        secureLocalStorage.setItem('clusterTypeLevel', '');
        secureLocalStorage.setItem('cluster', false);
        secureLocalStorage.setItem('clusterLevel', '');
        secureLocalStorage.setItem('location', false);
        secureLocalStorage.setItem('locationLevel', '');
        secureLocalStorage.setItem('productCluster', false);
        secureLocalStorage.setItem('productClusterLevel', '');

        secureLocalStorage.clear();
        localStorage.clear();
      }
    }
  }, []);

  function signOut() {
    try {
      Auth.signOut();
    } catch (error) {
      console.log('Error signing out: ', error);
    } finally {
      secureLocalStorage.clear();
      localStorage.clear();
    }
  }

  // window.addEventListener("storage", async function (e) {
  //   if (e.key.indexOf('@secure.') >= 0) {
  //     var checkLoggedIn = await Auth.currentAuthenticatedUser();

  //     await getUserAttributes(checkLoggedIn, 'signedin');
  //   }
  // }, false);

  useEffect(() => {
    Hub.listen('auth', handleAuthStateChange);

    loadMessages(esMessages);

    locale('es-MX');

    config({
      defaultCurrency: 'MXN'
    });

    async function checkIsLoggedIn() {
      try {
        var checkLoggedIn = await Auth.currentAuthenticatedUser();

        // Refrescamos los permisos desde Netsuite
        await getUserAttributes(checkLoggedIn, 'signedin');
      }
      catch (e) {
        try {
          Auth.signOut();
        } catch (error) {
          console.log('Error signing out: ', error);
        }
      }
    }

    checkIsLoggedIn();

    return () => Hub.remove('auth', handleAuthStateChange);
  }, [handleAuthStateChange]);

  return (
    <div>
      <HashRouter>
        <div>
          <table className="w-full bg-white" >
            <tbody>
              <tr>
                <th className="pl-3 w-1/6 pt-2 pb-2"><img src={logo} title="Vinoteca" alt="Vinoteca" width="280" /></th>
                <th className="w-1/5">
                </th>
              </tr>
            </tbody>
          </table>
          <React.Fragment>
            <Box sx={{ display: '-webkit-box', overflow: 'auto', alignItems: 'center', textAlign: 'center', backgroundColor: "#4B5563" }}>
              {/* *******MENU HOME******* */}
              <NavLink exact to="/">
                <Button
                  id="buttonHome"
                  aria-controls={openHome ? 'basic-menu' : undefined}
                  aria-haspopup="false"
                  aria-expanded={openHome ? 'true' : undefined}
                  onClick={handleClickHome}
                  style={{ fontSize: '17px', color: '#FFF', backgroundColor: "#4B5563", margin: '0', borderRadius: 0 }}
                >
                  <FontAwesomeIcon icon={faHome} size="lg" />
                </Button>
              </NavLink>


              {/* *******MENU CONFIGURATION******* */}
              {status === "signedin" && isadmin === "true" ?
                (
                  <div>
                    <Button
                      id="buttonConfiguration"
                      aria-controls={openConfiguration ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openConfiguration ? 'true' : undefined}
                      onClick={handleClickConfiguration}
                      endIcon={<KeyboardArrowDownIcon />}
                      style={{ fontSize: '15px', color: '#FFF', backgroundColor: "#4B5563", margin: '0', borderRadius: 0 }}
                    >
                      <div className="mr-2"><FontAwesomeIcon icon={faWrench} size="lg" /></div>Configuración
                    </Button>
                    <Menu
                      id="menuConfiguration"
                      anchorEl={anchorConfiguration}
                      open={openConfiguration}
                      onClose={handleCloseConfiguration}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      {(status === "signedin" && isadmin === "true") ?
                        (<NavLink to="/Signup"><MenuItem onClick={handleCloseConfiguration}><div className="mr-2"><FontAwesomeIcon icon={faUserPlus} size="lg" /></div>Agregar Usuario</MenuItem></NavLink>) :
                        (<div />)
                      }
                      {(status === "signedin" && isadmin === "true") ?
                        (<NavLink to="/Users"><MenuItem onClick={handleCloseConfiguration}><div className="mr-2"><FontAwesomeIcon icon={faUsers} size="lg" /></div>Lista de Usuarios</MenuItem></NavLink>) :
                        (<div />)
                      }
                    </Menu>
                  </div>
                ) :
                (<div></div>)
              }

              {/* *******MENU CLUSTER******* */}
              {status === "signedin" && (cluster === true || location === true || productCluster === true || clusterType === true) ?
                (
                  <div>
                    <Button
                      id="buttonCluster"
                      aria-controls={openCluster ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openCluster ? 'true' : undefined}
                      onClick={handleClickCluster}
                      endIcon={<KeyboardArrowDownIcon />}
                      style={{ fontSize: '15px', color: '#FFF', backgroundColor: "#4B5563", margin: '0', borderRadius: 0 }}
                    >
                      <div className="mr-2"><FontAwesomeIcon icon={faChartNetwork} size="lg" /></div>Cluster
                    </Button>
                    <Menu
                      id="menuCluster"
                      anchorEl={anchorCluster}
                      open={openCluster}
                      onClose={handleCloseCluster}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      {(status === "signedin" && clusterType === true) ?
                        (<NavLink to="/ClusterType"><MenuItem onClick={handleCloseCluster}><div className="mr-2"><FontAwesomeIcon icon={faTag} size="lg" /></div>Cluster Types</MenuItem></NavLink>) :
                        (<div />)
                      }
                      {(status === "signedin" && cluster === true) ?
                        (<NavLink to="/Clusters"><MenuItem onClick={handleCloseCluster}><div className="mr-2"><FontAwesomeIcon icon={faBox} size="lg" /></div>Cluster</MenuItem></NavLink>) :
                        (<div />)
                      }
                      {(status === "signedin" && location === true) ?
                        (<NavLink to="/Locations"><MenuItem onClick={handleCloseCluster}><div className="mr-2"><FontAwesomeIcon icon={faLocationDot} size="lg" /></div>Location</MenuItem></NavLink>) :
                        (<div />)
                      }
                      {(status === "signedin" && productCluster === true) ?
                        (<NavLink to="/ProductCluster"><MenuItem onClick={handleCloseCluster}><div className="mr-2"><FontAwesomeIcon icon={faBoxOpenFull} size="lg" /></div>Product-Cluster</MenuItem></NavLink>) :
                        (<div />)
                      }
                    </Menu>
                  </div>
                ) :
                (<div></div>)
              }

              {/* *******MENU ACCOUNT******* */}
              <IconButton
                onClick={handleClickAccount}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={openAccount ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openAccount ? 'true' : undefined}
              >
                <Avatar sx={{ width: 30, height: 30 }}></Avatar>
              </IconButton>

              <Menu
                anchorEl={anchorAccount}
                id="account-menu"
                open={openAccount}
                onClose={handleCloseAccount}
                onClick={handleCloseAccount}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                {status === "signedin" ?
                  (<div>
                    <MenuItem disabled={true} onClick={signOut}><div className="mr-2"><FontAwesomeIcon icon={faCircleUser} size="xl" /></div>Usuario {username}</MenuItem>
                    <Divider />
                  </div>) :
                  (<div />)
                }

                {status === "signedout" ?
                  (<NavLink to="/Signin"><MenuItem onClick={handleCloseAccount}><div className="mr-2"><FontAwesomeIcon icon={faSignInAlt} size="lg" /></div>Iniciar Sesión</MenuItem></NavLink>) :
                  (<div />)
                }
                {status === "signedin" ?
                  (<div>
                    <NavLink to="/PasswordChange"><MenuItem onClick={handleCloseConfiguration}><div className="mr-2"><FontAwesomeIcon icon={faKey} size="lg" /></div>Cambiar Contraseña</MenuItem></NavLink>
                    <Divider />
                  </div>
                  ) :
                  (<div />)
                }
                {status === "signedin" ?
                  (<NavLink to="/Signout"><MenuItem onClick={signOut}><div className="mr-2"><FontAwesomeIcon icon={faSignOutAlt} size="lg" /></div>Cerrar Sesión</MenuItem></NavLink>) :
                  (<div />)
                }
              </Menu>
            </Box>
          </React.Fragment>
          <div className="content">
            <Route exact path="/" render={(props) => <Home {...props} usuario={username} status={status} />} />

            {status === "signedout" ?
              (<Route exact path="/Signin" render={(props) => <Signin {...props} status={status} />} />) :
              (<Route />)
            }

            {(status === "signedin" && isadmin === "true") ?
              (<Route exact path="/Signup" render={(props) => <Signup {...props} status={status} />} />) :
              (<Route />)
            }
            {(status === "signedin" && isadmin === "true") ?
              (<Route path="/Users" render={(props) => <Users {...props} status={status} />} />) :
              (<Route />)
            }

            {(status === "signedin" && location === true) ?
              (<Route path="/Locations" render={(props) => <Locations {...props} status={status} nivel={locationLevel} />} />) :
              (<Route />)
            }
            {status === "signedin" ?
              (<Route path="/PasswordChange" render={(props) => <PasswordChange {...props} />} />) :
              (<Route />)
            }

            {status === "signedin" ?
              (<Route path="/Signout" component={Signout} />) :
              (<Route />)
            }

            {(status === "signedin" && cluster === true) ?
              (<Route path="/Clusters" render={(props) => <Clusters {...props} usuario={username} nivel={clusterLevel} />} />) :
              (<Route />)
            }

            {(status === "signedin" && clusterType === true) ?
              (<Route path="/ClusterType" render={(props) => <ClusterType {...props} usuario={username} nivel={clusterTypeLevel} />} />) :
              (<Route />)
            }

            {(status === "signedin" && productCluster === true) ?
              (<Route path="/ProductCluster" render={(props) => <ProductCluster {...props} usuario={username} nivel={productClusterLevel} />} />) :
              (<Route />)
            }
          </div>
        </div>
      </HashRouter>
    </div>
  );
}