import React, { useState, useEffect, useRef, useMemo } from 'react';

// LoadPanel
import { LoadPanel } from 'devextreme-react/load-panel';

// Toast
import { Toast } from 'devextreme-react/toast';

// Grid
import DataGrid, {
  Column, FilterRow, HeaderFilter, FilterPanel, FilterBuilderPopup,
  GroupPanel, Grouping, SearchPanel, Sorting, Editing, Lookup, Toolbar, Item, RequiredRule,
  Scrolling, Pager, Paging, Summary, TotalItem, StateStoring, ColumnChooser, Export,
  ColumnChooserSearch, ColumnChooserSelection, Search
} from 'devextreme-react/data-grid';

//CheckBox
import CheckBox from 'devextreme-react/check-box';
import TextArea from 'devextreme-react/text-area';

//TagBox
import TagBox from "devextreme/ui/tag_box";

//Form
import Form, { GroupItem, Label, TabbedItem, TabPanelOptions, Tab } from 'devextreme-react/form';

import { Auth, API, Storage } from 'aws-amplify';

// Ruteador
import { Route, Link, useHistory } from "react-router-dom";

// Iconos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlasses } from "@fortawesome/pro-solid-svg-icons";
import { faPencilAlt } from "@fortawesome/pro-solid-svg-icons";
import { faArrowRotateLeft } from "@fortawesome/pro-solid-svg-icons";
import { faSave } from "@fortawesome/pro-solid-svg-icons";
import { faList } from "@fortawesome/pro-duotone-svg-icons";
import { faTag } from "@fortawesome/pro-duotone-svg-icons";
import { faFileAlt } from "@fortawesome/pro-solid-svg-icons";

import './ClusterType.css';

import '../ListStyle.css';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter'

export default function Clusters(props) {
  const history = useHistory();

  const [isDetail, setIsDetail] = useState(false);
  const [id, setId] = useState();
  const [permissionLevel, setPermissionLevel] = useState(props.nivel);
  const [clusterRecords, setClusterRecords] = useState([]);

  const [clusterTypePayload, setClusterTypePayload] = useState({});

  const [activateSaveButton, setActivateSaveButton] = useState(false);
  const [activateUpdateForm, setActivateUpdateForm] = useState(false);

  const [autoExpandAll, setAutoExpandAll] = useState(true);

  const [visibleLoadPanel, setVisibleLoadPanel] = useState(false);

  const [visibleToast, setVisibleToast] = useState(false);
  const [messageToast, setMessageToast] = useState('');
  const [typeToast, setTypeToast] = useState('success');

  //Ref para el form
  const [formDatosGeneralesRef, setFormDatosGeneralesRef] = useState(React.createRef());

  function getOrderDay(rowData) {
    return (new Date(rowData.OrderDate)).getDay();
  };

  const filterBuilder = useMemo(() => {
    return {
      customOperations: [{
        name: 'weekends',
        caption: 'Weekends',
        dataTypes: ['date'],
        icon: 'check',
        hasValue: false,
        calculateFilterExpression: () => [[getOrderDay, '=', 0], 'or', [getOrderDay, '=', 6]],
      }],
      allowHierarchicalFields: true,
    };
  }, []);

  const filterBuilderPopupPosition = useMemo(() => {
    return {
      of: window,
      at: 'top',
      my: 'top',
      offset: { y: 10 },
    };
  }, []);

  const allowedPageSizes = useMemo(() => {
    return [5, 10, 15, 20, 'all'];
  }, []);

  const exportFormats = useMemo(() => {
    return ['xlsx'];
  }, []);

  const getClusterTypeList = React.useCallback(async () => {
    let apiName = 'portalvinoteca';
    let path = '/procesaclustertype';

    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      },
      body: {
        action: 'list',
        q: window.btoa(JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username)
      }
    }

    var clustersGet = await API.post(apiName, path, myInit);

    setClusterRecords(clustersGet.data);
  }, []);

  const getClusterTypePayload = React.useCallback(async (internalid, clusterTypeName) => {
    try {
      let apiName = 'portalvinoteca';
      let path = '/procesaclustertype';
      let myInit = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        body: {
          'action': 'payload'
          , 'internalid': internalid
          , 'name': clusterTypeName
          , 'q': window.btoa(JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username)
        }
      }

      var clusterTypePayload = await API.post(apiName, path, myInit);

      setClusterTypePayload(clusterTypePayload.data);
    } catch (e) {
      if (e === 'No current user') {
        history.push('/');

        window.location.reload();
      }
    }
  }, [history]);

  const newButton = React.useCallback(async () => {
    try {
      setVisibleLoadPanel(true);

      setClusterTypePayload({});

      setIsDetail(true);
      setId(undefined);

      setActivateUpdateForm(true);
      setActivateSaveButton(true);

      setVisibleLoadPanel(false);
    } catch (e) {
      if (e === 'No current user') {
        history.push('/');

        window.location.reload();
      }
    }
  }, [history]);

  const editClusterTypeButton = React.useCallback(async (internalid, clusterTypeName) => {
    try {
      if (!(window.event && (window.event.metaKey || window.event.ctrlKey))) {
        setVisibleLoadPanel(true);

        await getClusterTypePayload(internalid, clusterTypeName);

        setIsDetail(true);
        setId(internalid);

        if (permissionLevel === 'Editar') {
          setActivateUpdateForm(true);
          setActivateSaveButton(true);
        }
        else {
          setActivateUpdateForm(false);
          setActivateSaveButton(false);
        }

        setVisibleLoadPanel(false);
      }
    } catch (e) {
      setVisibleLoadPanel(false);

      if (!internalid || internalid === 'undefined') {
        newButton();
      }
    }
  }, [getClusterTypePayload, permissionLevel, newButton]);

  const cancelEditButton = React.useCallback(async () => {
    setVisibleLoadPanel(true);

    setIsDetail(false);

    await getClusterTypeList();

    setVisibleLoadPanel(false);

    history.push('/ClusterType');

  }, [getClusterTypeList, history]);

  const saveButton = React.useCallback(async () => {
    var locationName = clusterTypePayload.name;
    var internalid = clusterTypePayload.internalid;

    if (formDatosGeneralesRef.current) {
      var formDatosGenerales = formDatosGeneralesRef.current.instance;
      var isValid = formDatosGenerales.validate();

      if (!isValid.isValid) {
        setVisibleToast(true);
        setMessageToast(isValid.brokenRules[0].message);
        setTypeToast('error');
        return;
      }
    }

    try {
      setVisibleLoadPanel(true);

      // Actualizamos los datos en Netsuite
      let apiName = 'portalvinoteca';
      let path = '/procesaclustertype';

      let myInit = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        body: {
          locationName: locationName,
          internalid: internalid,
          action: 'postData',
          data: clusterTypePayload,
          q: window.btoa(JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username)
        }
      }

      var payloadPost = await API.post(apiName, path, myInit);

      if (payloadPost.success) {
        if (!internalid) {
          setVisibleToast(true);
          setMessageToast('Se creó el Cluster Type con éxito');
          setTypeToast('success')

          var newID = payloadPost.data.id;

          editClusterTypeButton(newID);

          history.push('/ClusterType/' + newID.toString());
        }
        else {
          setVisibleToast(true);
          setMessageToast('Se actualizó el Cluster Type con éxito');
          setTypeToast('success')
        }
      }
      else if (!payloadPost.success) {
        setVisibleToast(true);
        setMessageToast(payloadPost.message);
        setTypeToast('error')
      }

      editClusterTypeButton(internalid);

      setVisibleLoadPanel(false);
    }
    catch (e) {
      setVisibleLoadPanel(false);

      if (e === 'No current user') {
        history.push('/');

        window.location.reload();
      }
    }
  }, [history, clusterTypePayload, editClusterTypeButton, formDatosGeneralesRef]);

  const onFieldDataChanged = React.useCallback((e) => {
  }, []);

  const editClusterType = React.useCallback((data) => {
    return (
      <Link to={`/ClusterType/${data.data.internalid}`}>
        <button class="bg-blue-500 hover:bg-blue-700 text-white py-1 px-3 rounded" onClick={() => editClusterTypeButton(data.data.internalid, data.data.name)}><FontAwesomeIcon icon={permissionLevel === 'Editar' ? faPencilAlt : faGlasses} /> {permissionLevel === 'Editar' ? 'Editar' : 'Ver'}</button>
      </Link>
    )
  }, [editClusterTypeButton, permissionLevel]);

  const onAutoExpandAllChanged = React.useCallback(() => {
    setAutoExpandAll(!autoExpandAll)
  }, [autoExpandAll]);

  const clusterTypeEnabled = React.useCallback((data) => {
    var statusText = '';

    if (!data.data.isinactive)
      statusText = 'Activo';
    else
      statusText = 'Inactivo';

    return (
      <span className={!data.data.isinactive ? "px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-sm" : "px-2 py-1 font-semibold leading-tight text-red-700 bg-red-100 rounded-sm"}> {statusText} </span>
    )
  }, []);

  var internalidEditorOptions = useMemo(() => {
    return { width: '100%', readOnly: true, visible: false };
  }, []);

  var clusterTypeNameEditorOptions = useMemo(() => {
    return { width: '100%', inputAttr: { 'style': 'text-transform: uppercase' } };
  }, []);

  //Opciones Nivel de Permiso
  const levelOptions = useMemo(() => {
    return [
      { value: 1, name: 'Ver' },
      { value: 2, name: 'Editar' }
    ];
  }, []);

  var toastPosition = useMemo(() => {
    return {
      my: 'top center',
      at: 'top center',
      of: window,
      offset: '0 130'
    }
  }, []);

  useEffect(() => {
    async function initialize() {
      setVisibleLoadPanel(true);

      await getClusterTypeList();

      setVisibleLoadPanel(false);
    }

    initialize();

    // En caso de solicitar un ID especifico, redireccionamos a ese ID
    if (!props.match.isExact) {
      var urlID = props.location.pathname.replace('/ClusterType/', '');

      if (urlID === 'undefined')
        urlID = undefined;

      if (urlID)
        editClusterTypeButton(urlID);
      else
        newButton();
    }

  }, [editClusterTypeButton, getClusterTypeList, props.location.pathname, props.match.isExact, newButton]);

  const onHidingToast = React.useCallback(() => {
    setVisibleToast(false);
  }, []);

  function onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Datos');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Datos.xlsx');
      });
    });
    e.cancel = true;
  };

  return (
    <div>
      {isDetail === false ? (
        <div class="bg-gray-200 m-2">
          <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faList} size="lg" /> Cluster Types</h1>
        </div>
      ) :
        (
          <div class="bg-gray-200 m-2">
            <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1 pl-3"><FontAwesomeIcon icon={faTag} size="xl" /> {clusterTypePayload.name}</h1>
          </div>
        )}

      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        visible={visibleLoadPanel}
        showIndicator={true}
        message="Por favor espere..."
        shading={true}
        showPane={true}
        hideOnOutsideClick={false}
      />

      <Toast
        visible={visibleToast}
        message={messageToast}
        type={typeToast}
        onHiding={onHidingToast}
        displayTime={5000}
        width={'auto'}
        position={toastPosition}
      />

      <div class="bg-gray-200 m-2">
        {isDetail === false ? (
          <div class="bg-white p-3 shadow-xl">

            {permissionLevel === 'Editar' ?
              (
                <div>
                  <Link to="/ClusterType/undefined">
                    <button class="mb-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" onClick={() => newButton()}><FontAwesomeIcon icon={faFileAlt} /> Nuevo Cluster Type</button>
                  </Link>
                </div>
              ) :
              (
                <div>
                </div>
              )}

            <div className="options mb-2">
              <div className="option">
                <CheckBox text="Expandir todos los grupos"
                  value={autoExpandAll}
                  onValueChanged={onAutoExpandAllChanged}>
                </CheckBox>
              </div>
            </div>
            <DataGrid
              id="gridContainer"
              columnsAutoWidth={true}
              filterBuilder={filterBuilder}
              dataSource={clusterRecords}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnResizingMode="widget"
              showBorders={true}
              rowAlternationEnabled={true}
              showRowLines={true}
              showColumnLines={true}
              onExporting={onExporting}
            >
              <Scrolling rowRenderingMode='virtual'></Scrolling>
              <Paging defaultPageSize={10} />
              <Pager
                visible={true}
                allowedPageSizes={allowedPageSizes}
                displayMode='compact'
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true} />
              <FilterRow visible={true} />
              <FilterPanel visible={true} />
              <FilterBuilderPopup position={filterBuilderPopupPosition} />

              <HeaderFilter visible={true} />

              <GroupPanel visible={true} />
              <SearchPanel visible={true} />
              <ColumnChooser
                enabled={true}
                mode='select'
              >
                <ColumnChooserSearch
                  enabled={true}
                />

                <ColumnChooserSelection
                  allowSelectAll={true}
                  selectByClick={true}
                  recursive={true}
                />
              </ColumnChooser>
              <Grouping autoExpandAll={autoExpandAll} />
              <Export enabled={true} />
              <StateStoring enabled={true} type="localStorage" storageKey="gridContainerListClusters" />

              <Column caption="" alignment="center" width={100} cellRender={editClusterType} >
                <HeaderFilter>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              <Column caption="Nombre" dataField="name" dataType="string" width={400} >
                <HeaderFilter>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              <Column caption="internalid" dataField="internalid" dataType="number" width={200} visible={false} >
                <HeaderFilter>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              <Column caption="Estatus" alignment="center" width={200} cellRender={clusterTypeEnabled} >
                <HeaderFilter>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
            </DataGrid>
          </div>
        ) : (<div />)}
        <Route exact path="/ClusterType/:clusterId" render={({ match }) => {
          return (
            <div class="m-3 bg-gray-200">
              {isDetail.toString() === 'true' ?
                (
                  <div>
                    <Link to="/ClusterType">
                      <button onClick={cancelEditButton} type="submit" class="mb-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                        <FontAwesomeIcon icon={faArrowRotateLeft} /> Regresar
                      </button>
                    </Link>

                    {activateSaveButton.toString() === 'true' ?
                      (
                        <button onClick={saveButton} type="submit" class="ml-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                          <FontAwesomeIcon icon={faSave} /> Guardar Cambios
                        </button>
                      ) : (
                        <div class="inline-block"></div>
                      )}

                    <div class="mt-1 bg-white pt-3 shadow-xl">
                      <div class="ml-4 mr-4 bg-white pt-3">
                        <Form id="datosGenerales"
                          labelMode={'outside'}
                          formData={clusterTypePayload}
                          readOnly={!activateUpdateForm}
                          showColonAfterLabel={false}
                          labelLocation={'left'}
                          colCount={2}
                          onFieldDataChanged={onFieldDataChanged}
                          ref={formDatosGeneralesRef}
                        >
                          <GroupItem caption="Datos Generales">
                            <Item cssClass="boldText" dataField="internalid" editorOptions={internalidEditorOptions} >
                              <Label text="" visible={false} />
                            </Item>
                            <Item cssClass="boldText" dataField="name" editorOptions={clusterTypeNameEditorOptions} >
                              <Label text={"Nombre"} />
                              <RequiredRule message="Nombre es requerido" />
                            </Item>
                            <Item dataField="isinactive" editorType="dxCheckBox" >
                              <Label text="Inactivo" />
                            </Item>

                          </GroupItem>
                        </Form>
                      </div>
                    </div>
                  </div>
                ) :
                (<div></div>)
              }
            </div>
          );
        }}
        />
      </div>
    </div>
  );
}