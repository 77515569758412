import React, { useState, useEffect, useRef } from 'react';

// LoadPanel
import { LoadPanel } from 'devextreme-react/load-panel';

// Toast
import { Toast } from 'devextreme-react/toast';

// Iconos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/pro-solid-svg-icons";
import { faKey } from "@fortawesome/pro-duotone-svg-icons";

import { Auth, API, Storage } from 'aws-amplify';

// Ruteador
import { Route, Link, useHistory } from "react-router-dom";

//Form
import Form, { GroupItem, Label, Item } from 'devextreme-react/form';

//Validator
import { EmailRule, CustomRule, RequiredRule } from 'devextreme-react/validator';

export default function PasswordChange(props) {
    useEffect(() => {
        async function initialize() {
            var checkLoggedIn = await Auth.currentAuthenticatedUser();

            var newState = {
                userName: checkLoggedIn.username,
                oldPassword: '',
                newPassword: '',
                confirmPassword: ''
            };

            setPasswordPayload(newState);
        }

        initialize();
        // eslint-disable-next-line
    }, []);

    const history = useHistory();

    const [passwordPayload, setPasswordPayload] = useState({
        userName: '',
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
    });

    var userNameEditorOptions = { width: '40%', readOnly: true };
    var passwordEditorOptions = { width: '40%', disabled: false, mode: 'password' };

    const [visibleLoadPanel, setVisibleLoadPanel] = useState(false);

    const [visibleToast, setVisibleToast] = useState(false);
    const [messageToast, setMessageToast] = useState('');
    const [typeToast, setTypeToast] = useState('success');

    //Ref para el form
    const [formPasswordRef, setFormPasswordRef] = useState(React.createRef());

    async function saveButton() {
        if (formPasswordRef.current) {
            var formSignUp = formPasswordRef.current.instance;
            var isValid = formSignUp.validate();

            if (!isValid.isValid) {
                setVisibleToast(true);
                setMessageToast(isValid.brokenRules[0].message);
                setTypeToast('error');
                return;
            }
        }

        var userName = passwordPayload.userName;
        var oldPassword = passwordPayload.oldPassword;
        var newPassword = passwordPayload.newPassword;
        var confirmPassword = passwordPayload.confirmPassword;

        if (!newPassword)
            newPassword = '';
        if (!confirmPassword)
            confirmPassword = '';

        if (!userName) {
            setVisibleToast(true);
            setMessageToast('Usuario incorrecto');
            setTypeToast('error')

            return;
        }
        if (!oldPassword) {
            setVisibleToast(true);
            setMessageToast('Contraseña actual incorrecta');
            setTypeToast('error')

            return;
        }
        if (!oldPassword) {
            setVisibleToast(true);
            setMessageToast('Contraseña actual incorrecta');
            setTypeToast('error')

            return;
        }

        if ((newPassword !== confirmPassword) || newPassword === '' || confirmPassword === '') {
            setVisibleToast(true);
            setMessageToast('Las contraseñas no coinciden');
            setTypeToast('error')

            return;
        }

        setVisibleLoadPanel(true);

        var user = await Auth.currentAuthenticatedUser();

        try {
            var result = await Auth.changePassword(user, oldPassword, newPassword);

            setVisibleToast(true);
            setMessageToast('Se cambió la contraseña con éxito');
            setTypeToast('success')

            setVisibleLoadPanel(false);
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e.toString().indexOf('LimitExceededException') >= 0) {
                setVisibleToast(true);
                setMessageToast('Ha excedido los intentos. Intente más tarde');
                setTypeToast('error')
            }
            else if (e.toString().indexOf('NotAuthorizedException') >= 0) {
                setVisibleToast(true);
                setMessageToast('Usuario o contraseña incorrectos');
                setTypeToast('error')
            }
            else if (e.toString().indexOf('InvalidPasswordException: Password did not conform with policy: Password not long enough') >= 0) {
                setVisibleToast(true);
                setMessageToast('La contraseña nueva no cumple con la política. Longitud Incorrecta');
                setTypeToast('error');
            }
            else {
                setVisibleToast(true);
                setMessageToast(e);
                setTypeToast('error');
            }
        }
    }

    function onHidingToast() {
        setVisibleToast(false);
    }

    return (
        <div>
            <div class="bg-gray-200 m-2">
                <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faKey} /> Cambio de contraseña</h1>
            </div>
            <button onClick={saveButton} type="submit" class="mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                <FontAwesomeIcon icon={faSave} /> Guardar Cambios
            </button>
            <div className="bg-white p-4 shadow-xl">
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    visible={visibleLoadPanel}
                    showIndicator={true}
                    message="Por favor espere..."
                    shading={true}
                    showPane={true}
                    hideOnOutsideClick={false}
                />

                <Toast
                    visible={visibleToast}
                    message={messageToast}
                    type={typeToast}
                    onHiding={onHidingToast}
                    displayTime={5000}
                    width={'auto'}
                    position={{
                        my: 'top center',
                        at: 'top center',
                        of: window,
                        offset: '0 130'
                    }}
                />
                <Form
                    id="formPassword"
                    labelMode={'outside'}
                    formData={passwordPayload}
                    disabled={false}
                    showColonAfterLabel={false}
                    labelLocation={'left'}
                    colCount={1}
                    ref={formPasswordRef}
                >
                    <Item cssClass="boldText" dataField="userName" editorOptions={userNameEditorOptions}>
                        <Label text={"Usuario"} />
                        <RequiredRule message="Usuario es requerido" />
                    </Item>
                    <Item dataField="oldPassword" editorOptions={passwordEditorOptions}>
                        <Label text={"Contraseña Actual"} />
                        <RequiredRule message="Contraseña Actual es requerida" />
                    </Item>
                    <Item dataField="newPassword" editorOptions={passwordEditorOptions}>
                        <Label text={"Nueva Contraseña"} />
                        <RequiredRule message="Nueva Contraseña es requerida" />
                    </Item>
                    <Item dataField="confirmPassword" editorOptions={passwordEditorOptions}>
                        <Label text={"Confirmar Contraseña"} />
                        <RequiredRule message="Confirmar Contraseña es requerida" />
                    </Item>
                </Form>
            </div>
        </div>
    )
};