import React, { useState, useEffect, useRef, useMemo } from 'react';

// LoadPanel
import { LoadPanel } from 'devextreme-react/load-panel';

// Toast
import { Toast } from 'devextreme-react/toast';

// Grid
import DataGrid, {
  Column, FilterRow, HeaderFilter, FilterPanel, FilterBuilderPopup,
  GroupPanel, Grouping, SearchPanel, Sorting, Editing, Lookup, Toolbar, Item, RequiredRule,
  Scrolling, Pager, ColumnFixing, Paging, Summary, TotalItem, StateStoring, ColumnChooser, ColumnChooserSearch, ColumnChooserSelection, Export,
  Template, Checkbox, Search
} from 'devextreme-react/data-grid';

//CheckBox
import CheckBox from 'devextreme-react/check-box';

//confirm
import { confirm } from 'devextreme/ui/dialog';

import DataSource from 'devextreme/data/data_source';
//Form
import Form, { GroupItem, Label, TabbedItem, TabPanelOptions, Tab } from 'devextreme-react/form';
import { Auth, API, Storage } from 'aws-amplify';

// Ruteador
import { Route, Link, useHistory } from "react-router-dom";

// Iconos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlasses } from "@fortawesome/pro-solid-svg-icons";
import { faPencilAlt } from "@fortawesome/pro-solid-svg-icons";
import { faArrowRotateLeft } from "@fortawesome/pro-solid-svg-icons";
import { faSave } from "@fortawesome/pro-solid-svg-icons";
import { faList } from "@fortawesome/pro-duotone-svg-icons";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { faLock } from "@fortawesome/pro-solid-svg-icons";
import { faArrowsRotate } from "@fortawesome/pro-solid-svg-icons";

import { faLocationDot } from "@fortawesome/pro-duotone-svg-icons";


import './Locations.css';

import '../ListStyle.css';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter'

export default function Locations(props) {
  const history = useHistory();

  const [isDetail, setIsDetail] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(false);

  const [permissionLevel, setPermissionLevel] = useState(props.nivel);
  const [locationRecords, setLocationRecords] = useState([]);

  const [updateCount, setUpdateCount] = useState(0);

  const [locationPayload, setLocationPayload] = useState({});
  const [clusters, setClusters] = useState([]);

  const [clusterType, setClusterType] = useState([]);

  const [activateUpdateGrid, setActivateUpdateGrid] = useState(false);
  const [activateSaveButton, setActivateSaveButton] = useState(false);
  const [activateUpdateForm, setActivateUpdateForm] = useState(false);

  const [autoExpandAll, setAutoExpandAll] = useState(true);

  const [gridClustersRef, setGridClustersRef] = useState(React.createRef());
  const [gridProductLocationRef, setGridProductLocationRef] = useState(React.createRef());

  //Controles para expander los grupos del grid de checklist
  const [autoExpandAllChecklist, setAutoExpandAllChecklist] = useState(true);

  const [visibleLoadPanel, setVisibleLoadPanel] = useState(false);

  const [visibleToast, setVisibleToast] = useState(false);
  const [messageToast, setMessageToast] = useState('');
  const [typeToast, setTypeToast] = useState('success');


  const [productsFromLocationList, setProductsFromLocationList] = useState([]);
  const [clusterList, setClusterList] = useState([]);
  const [clusterTypeList, setClusterTypeList] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [itemType, setItemType] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [exclusivo, setExclusivo] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [formDatosGeneralesRef, setFormDatosGeneralesRef] = useState(React.createRef());


  function getOrderDay(rowData) {
    return (new Date(rowData.OrderDate)).getDay();
  };

  const filterBuilder = useMemo(() => {
    return {
      customOperations: [{
        name: 'weekends',
        caption: 'Weekends',
        dataTypes: ['date'],
        icon: 'check',
        hasValue: false,
        calculateFilterExpression: () => [[getOrderDay, '=', 0], 'or', [getOrderDay, '=', 6]],
      }],
      allowHierarchicalFields: true,
    };
  }, []);

  const filterBuilderPopupPosition = useMemo(() => {
    return {
      of: window,
      at: 'top',
      my: 'top',
      offset: { y: 10 },
    };
  }, []);

  const allowedPageSizes = useMemo(() => {
    return [5, 10, 15, 20, 'all'];
  }, []);

  const exportFormats = useMemo(() => {
    return ['xlsx'];
  }, []);

  const getLocationsList = React.useCallback(async () => {
    let apiName = 'portalvinoteca';
    let path = '/procesalocation';

    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      },
      body: {
        action: 'list',
        q: window.btoa(JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username)
      }
    }

    var locationsGet = await API.post(apiName, path, myInit);

    setLocationRecords(locationsGet.data);
  }, []);


  const getClusterList = React.useCallback(async () => {
    var locations = [];

    let apiName = 'portalvinoteca';
    let path = '/procesahelper';

    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      },
      body: {
        action: 'clusterList',
        q: window.btoa(JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username)
      }
    }

    var clustersGet = await API.post(apiName, path, myInit);

    setClusters(clustersGet.data);

  }, []);

  const getClusterTypeList = React.useCallback(async () => {
    var locations = [];

    let apiName = 'portalvinoteca';
    let path = '/procesahelper';

    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      },
      body: {
        action: 'clusterTypeList',
        q: window.btoa(JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username)
      }
    }

    var clusterTypeGet = await API.post(apiName, path, myInit);

    setClusterType(clusterTypeGet.data);
  }, []);

  const getLocationPayload = React.useCallback(async (internalid) => {
    try {
      let apiName = 'portalvinoteca';
      let path = '/procesalocation';
      var location = {};
      let myInit = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        body: {
          action: 'payload',
          internalid: internalid,
          q: window.btoa(JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username)
        }
      }

      var locationPayload = await API.post(apiName, path, myInit);

      setLocationPayload(locationPayload.data);
    }
    catch (e) {
      if (e === 'No current user') {
        history.push('/');

        window.location.reload();
      }
    }
  }, [history]);


  const getProductsFromLocation = React.useCallback(async (internalid) => {
    var custitem_al_ecommerce_categoria = locationPayload.custitem_al_ecommerce_categoria;
    var custitem_al_ecommerce_departamento = locationPayload.custitem_al_ecommerce_departamento;
    var custitem_efx_cmp_exclusivo = locationPayload.custitem_efx_cmp_exclusivo;
    var vendor = locationPayload.vendor;
    var internalid = locationPayload.internalid;
    var filters = [];

    if (custitem_al_ecommerce_categoria) {
      filters.push(['custrecord_gv_rlx_articulo.custitem_al_ecommerce_categoria', 'is', custitem_al_ecommerce_categoria])
    }
    if (custitem_al_ecommerce_departamento) {
      if (filters.length > 0) {
        filters.push('and');
      }

      filters.push(['custrecord_gv_rlx_articulo.custitem_al_ecommerce_departamento', 'is', custitem_al_ecommerce_departamento])
    }
    if (custitem_efx_cmp_exclusivo) {
      if (filters.length > 0) {
        filters.push('and');
      }

      filters.push(['custrecord_gv_rlx_articulo.custitem_efx_cmp_exclusivo', 'is', custitem_efx_cmp_exclusivo])
    }
    if (vendor) {
      if (filters.length > 0) {
        filters.push('and');
      }

      filters.push(['custrecord_gv_rlx_articulo.vendor', 'is', vendor])
    }

    let apiName = 'portalvinoteca';
    let path = '/procesalocation';

    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      },
      body: {
        action: 'getDetail',
        filters: filters,
        internalid: internalid,
        q: window.btoa(JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username)
      }
    }

    var itemsGet = await API.post(apiName, path, myInit);
    if (itemsGet.data.products) {
      setProductsFromLocationList(itemsGet.data.products);

    }

  }, [locationPayload, setProductsFromLocationList]);


  const editLocationButton = React.useCallback(async (internalid) => {
    if (!(window.event && (window.event.metaKey || window.event.ctrlKey))) {
      setVisibleLoadPanel(true);

      setIsDetail(true);

      await getLocationPayload(internalid);

      if (permissionLevel === 'Editar') {
        setActivateUpdateForm(true);
        setActivateUpdateGrid(true);
        setActivateSaveButton(true);
      }
      else {
        setActivateUpdateForm(false);
        setActivateUpdateGrid(false);
        setActivateSaveButton(false);
      }

      setVisibleLoadPanel(false);
    }
  }, [getLocationPayload, permissionLevel]);

  const cancelEditButton = React.useCallback(async () => {
    setVisibleLoadPanel(true);

    setIsDetail(false);

    await getLocationsList();

    setVisibleLoadPanel(false);

    history.push('/Locations');
  }, [getLocationsList, history]);

  const saveButton = React.useCallback(async () => {
    var locationName = locationPayload.name;
    var internalid = locationPayload.internalid;

    try {
      //Primero guardamos los cambios del grid Clusters
      if (gridClustersRef.current) {
        var gridClusters = gridClustersRef.current.instance;
        // Check if any row has empty or null cells before saving
        for (var i = 0; i < gridClusters.getDataSource().items().length; i++) {
          var row = gridClusters.getDataSource().items()[i];
          var hasEmptyCells = Object.values(row).some(x => (x === null || x === ''));

          if (hasEmptyCells) {
            setVisibleToast(true);
            setMessageToast("No se puede guardar una fila con una celda vacía. Por favor, borre la fila.");
            setTypeToast('warning');
            setVisibleLoadPanel(false);
            return;  // prevent saving and further processing
          }
        }

        gridClusters.saveEditData();
      }

      var result = confirm("<i>La asociación o desasociación de clusters a la ubicación actualizará miles de registros en Netsuite y solo debe realizarse por las mañanas. ¿Desea continuar?</i>", "Asociación de Clusters");

      result.then(async (dialogResult) => {
        if (dialogResult) {
          setVisibleLoadPanel(true);

          // Actualizamos los datos en Netsuite
          let apiName = 'portalvinoteca';
          let path = '/procesalocation';

          let myInit = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            body: {
              locationName: locationName,
              internalid: internalid,
              action: 'postData',
              data: locationPayload,
              q: window.btoa(JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username)
            }
          }

          var payloadPost = await API.post(apiName, path, myInit);

          if (payloadPost.success) {
            setVisibleToast(true);
            setMessageToast('Se actualizó con éxito');
            setTypeToast('success')
          }
          else if (!payloadPost.success) {
            setVisibleToast(true);
            setMessageToast(payloadPost.message);
            setTypeToast('error')
          }

          editLocationButton(internalid);

          setVisibleLoadPanel(false);
        }
      });
    }
    catch (e) {
      setVisibleLoadPanel(false);

      if (e === 'No current user') {
        history.push('/');

        //window.location.reload();
      }
    }
  }, [history, locationPayload, editLocationButton, gridClustersRef]);

  const onFieldDataChanged = React.useCallback((e) => {
  }, []);

  const editLocation = React.useCallback((data) => {
    return (
      <Link to={`/Locations/${data.data.internalid}`}>
        <button className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-3 rounded" onClick={() => editLocationButton(data.data.internalid)}><FontAwesomeIcon icon={permissionLevel === 'Editar' ? faPencilAlt : faGlasses} /> {permissionLevel === 'Editar' ? 'Editar' : 'Ver'}</button>
      </Link>
    )
  }, [editLocationButton, permissionLevel])

  const onAutoExpandAllChanged = React.useCallback(() => {
    setAutoExpandAll(!autoExpandAll)
  }, [autoExpandAll]);
  //-----------------------------------------------------------------------------------------------------------------

  try {
    var departamentoEditorOptions = useMemo(() => {
      return {
        items: departamentos,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: true,
        value: (productsFromLocationList.custitem_al_ecommerce_departamento ? productsFromLocationList.custitem_al_ecommerce_departamento : ''),
        width: '100%',
        disabled: false,
        showClearButton: true
      };
    }, [productsFromLocationList.custitem_al_ecommerce_departamento, departamentos]);

  }
  catch (e) {
    var departamentoEditorOptions = {
      items: departamentos,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: true,
      value: '',
      width: '100%',
      showClearButton: true
    };
  }

  try {
    var categoriaEditorOptions = useMemo(() => {
      return {
        items: categorias,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: true,
        value: (productsFromLocationList.custitem_al_ecommerce_categoria ? productsFromLocationList.custitem_al_ecommerce_categoria : ''),
        width: '100%',
        disabled: false,
        showClearButton: true
      };
    }, [productsFromLocationList.custitem_al_ecommerce_categoria, categorias]);

  }
  catch (e) {
    var categoriaEditorOptions = {
      items: categorias,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: true,
      value: '',
      width: '100%',
      showClearButton: true
    };
  }

  try {
    var exclusivoEditorOptions = useMemo(() => {
      return {
        items: exclusivo,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: true,
        value: (locationPayload.custitem_efx_cmp_exclusivo ? locationPayload.custitem_efx_cmp_exclusivo : ''),
        width: '100%',
        disabled: false,
        showClearButton: true
      };
    }, [locationPayload.custitem_efx_cmp_exclusivo, exclusivo]);

  }
  catch (e) {
    var exclusivoEditorOptions = {
      items: exclusivo,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: true,
      value: '',
      width: '100%',
      showClearButton: true
    };
  }

  var proveedorDataSource = useMemo(() => {
    return new DataSource({
      paginate: true,
      store: {
        type: "array",
        key: "value",
        data: proveedores
      }
    });
  }, [proveedores]);

  try {
    var vendorEditorOptions = useMemo(() => {
      return {
        dataSource: proveedorDataSource,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: true,
        value: (locationPayload.vendor ? locationPayload.vendor : ''),
        width: '100%',
        disabled: false,
        showClearButton: true
      };
    }, [locationPayload.vendor, proveedorDataSource]);

  }
  catch (e) {
    var vendorEditorOptions = {
      items: proveedores,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: true,
      value: '',
      width: '100%',
      showClearButton: true
    };
  }

  //-----------------------------------------------------------------------------------------------------------------
  var internalidEditorOptions = useMemo(() => {
    return { width: '100%', readOnly: true, visible: false };
  }, []);

  var tipoUbicacionEditorOptions = useMemo(() => {
    return { width: '100%', readOnly: true, visible: true };
  }, []);

  var nombreCortoEditorOptions = useMemo(() => {
    return { width: '100%', readOnly: true, visible: true };
  }, []);

  var parentEditorOptions = useMemo(() => {
    return { width: '100%', readOnly: true, visible: true };
  }, []);

  var subsidiaryEditorOptions = useMemo(() => {
    return { width: '100%', readOnly: true, visible: true };
  }, []);

  var relexLocationTypeEditorOptions = useMemo(() => {
    return { width: '100%', readOnly: true, visible: true };
  }, []);

  var relexOpeningDateEditorOptions = useMemo(() => {
    return { width: '100%', readOnly: true, visible: true };
  }, []);

  var relexClosingDateEditorOptions = useMemo(() => {
    return { width: '100%', readOnly: true, visible: true };
  }, []);

  var relexSafetyLeadTimeEditorOptions = useMemo(() => {
    return { width: '100%', readOnly: true, visible: true };
  }, []);

  var relexCDCEditorOptions = useMemo(() => {
    return { width: '100%', readOnly: true, visible: true };
  }, []);

  var relexCDCIDEditorOptions = useMemo(() => {
    return { width: '100%', readOnly: true, visible: true };
  }, []);

  var relexIDEditorOptions = useMemo(() => {
    return { width: '100%', readOnly: true, visible: true };
  }, []);

  var relexReceiptLocationEditorOptions = useMemo(() => {
    return { width: '100%', readOnly: true, visible: true };
  }, []);

  var relexReceiptLocationIDEditorOptions = useMemo(() => {
    return { width: '100%', readOnly: true, visible: true };
  }, []);

  var relexTMKLocationEditorOptions = useMemo(() => {
    return { width: '100%', readOnly: true, visible: true };
  }, []);

  var relexTMKIDLocationEditorOptions = useMemo(() => {
    return { width: '100%', readOnly: true, visible: true };
  }, []);

  var toastPosition = useMemo(() => {
    return {
      my: 'top center',
      at: 'top center',
      of: window,
      offset: '0 130'
    }
  }, []);






  const getDepartmentList = React.useCallback(async () => {
    let apiName = 'portalvinoteca';
    let path = '/procesahelper';

    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      },
      body: {
        action: 'departmentList',
        q: window.btoa(JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username)
      }
    }

    var departmentsGet = await API.post(apiName, path, myInit);

    setDepartamentos(departmentsGet.data);

  }, []);

  const getCategoryList = React.useCallback(async (Departamento) => {
    let apiName = 'portalvinoteca';
    let path = '/procesahelper';

    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      },
      body: {
        action: 'categoryList',
        filter: ['custrecord_al_ecommerce_categoria_depto', 'is', Departamento],
        q: window.btoa(JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username)
      }
    }

    var categoriesGet = await API.post(apiName, path, myInit);

    setCategorias(categoriesGet.data);

  }, []);

  const getExclusiveList = React.useCallback(async () => {
    let apiName = 'portalvinoteca';
    let path = '/procesahelper';

    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      },
      body: {
        action: 'exclusiveList',
        q: window.btoa(JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username)
      }
    }

    var exclusiveGet = await API.post(apiName, path, myInit);

    setExclusivo(exclusiveGet.data);

  }, []);

  const getVendorList = React.useCallback(async () => {
    let apiName = 'portalvinoteca';
    let path = '/procesahelper';

    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      },
      body: {
        action: 'vendorList',
        q: window.btoa(JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username)
      }
    }

    var vendorGet = await API.post(apiName, path, myInit);

    setProveedores(vendorGet.data);
  }, []);


  const refreshButton = React.useCallback(async () => {
    if (updateCount > 0) {
      if (!window.confirm('Existen ' + updateCount + ' cambios pendientes. ¿Desea descartarlos?'))
        return;
    }

    setUpdateCount(0);
    setActivateSaveButton(false);

    if (gridProductLocationRef.current) {
      var gridProductLocation = gridProductLocationRef.current.instance;

      setVisibleLoadPanel(true);


      var departamento = locationPayload.custitem_al_ecommerce_departamento;
      var exclusivo = locationPayload.custitem_efx_cmp_exclusivo;
      var vendor = locationPayload.vendor;
      var has_filters = departamento || exclusivo || vendor
      //var has_filters = false;
      if (has_filters) {
        await getProductsFromLocation();
      }
      else {
        setVisibleToast(true);
        setMessageToast("Seleccione filtros para actualizar la lista de productos.");
        setTypeToast('warning');
      }


      setVisibleLoadPanel(false);

      gridProductLocation.refresh();
    }
  }, [getProductsFromLocation, gridProductLocationRef, updateCount, locationPayload]);


  useEffect(() => {

    async function initialize() {
      setVisibleLoadPanel(true);

      await getLocationsList();

      await getClusterList();
      await getClusterTypeList();
      await getDepartmentList();

      await getExclusiveList();

      await getVendorList();


      setVisibleLoadPanel(false);
    }

    initialize();

    // En caso de solicitar un ID especifico, redireccionamos a ese ID
    if (!props.match.isExact) {
      var urlID = props.location.pathname.replace('/Locations/', '');

      editLocationButton(urlID);
    }

  }, [editLocationButton, getLocationsList, props.location.pathname, props.match.isExact, getClusterList, getClusterTypeList, getDepartmentList, getExclusiveList, getVendorList]);

  const onHidingToast = React.useCallback(() => {
    setVisibleToast(false);
  }, []);

  const onEditorClustersPreparing = React.useCallback((e) => {
    if (e.parentType === 'dataRow' && e.dataField === 'custrecord_al_portal_loc_cluster_clus') {
      if (e.row.data.internalid) {
        e.editorElement.classList.add('locked-lookup');
      }
    }
  }, []);

  const onCellClickClusters = React.useCallback((e) => {
    if (gridClustersRef.current) {
      setSelectedRowIndex(gridClustersRef.current.instance.getRowIndexByKey(e.key));
    }

  }, [gridClustersRef]);
  const onCellEditingStart = React.useCallback((e) => {
    if (gridClustersRef.current) {
      setSelectedRowIndex(gridClustersRef.current.instance.getRowIndexByKey(e.key));
    }

  }, [gridClustersRef]);

  const deleteRow = React.useCallback(() => {
    if (selectedRowIndex !== null && gridClustersRef.current) {

      try {
        gridClustersRef.current.instance.deleteRow(selectedRowIndex);
      } catch (error) {
      }

    }
  }, [gridClustersRef, selectedRowIndex]);

  const onFormFieldChanged = React.useCallback(async (e) => {
    var dataField = e.dataField;
    var value = e.value;

    if (formDatosGeneralesRef.current) {
      var formDatosGenerales = formDatosGeneralesRef.current.instance;

      if (e.dataField === 'custitem_al_ecommerce_departamento') {
        formDatosGenerales.updateData('custitem_al_ecommerce_categoria', undefined);

        if (e.value) {
          await getCategoryList(e.value);
        }
      }
    }
  }, [formDatosGeneralesRef, getCategoryList]);


  const setCellValueCluster = React.useCallback((rowData, value) => {
    var clusterID = parseInt(value);
    var editingRow = locationPayload.clusterList[selectedRowIndex];

    if (clusterID) {
      var input_cluster_type = clusters.find(cluster => parseInt(cluster.value) === clusterID).tipo;

      var duplicate = locationPayload.clusterList.find(cluster => (cluster.clusterType === input_cluster_type && cluster.isinactive === false));
      if (editingRow && (editingRow.internalid)) {
        setVisibleToast(true);
        setMessageToast("Cluster previamente asociado a la ubicación. No es posible modificarlo.");
        setTypeToast('warning')
        if (gridClustersRef.current) {
          if (selectedRowIndex >= 0) {
            gridClustersRef.current.instance.cancelEditData();
          }
        }
      }
      else if (duplicate) {
        setVisibleToast(true);
        setMessageToast("Ya agregó un Cluster tipo " + input_cluster_type + " a la Ubicación.");
        setTypeToast('warning')
        if (gridClustersRef.current) {

          // discard all changes
          if (selectedRowIndex >= 0) {
            // Delete the row
            deleteRow();
          }
        }
      }
      else {
        rowData.custrecord_al_portal_loc_cluster_clus = clusterID;

        for (var clusterIndex in clusters) {
          var singleCluster = clusters[clusterIndex];

          if (parseInt(singleCluster.value) === clusterID) {
            rowData.clusterName = singleCluster.name;
            rowData.clusterType = singleCluster.tipo;
            rowData.isinactive = false;
          }
        }
      }
    } else {
      var gridClusters = gridClustersRef.current.instance;
      gridClusters.deleteRow(selectedRowIndex)  // discard all changes

    }
  }, [gridClustersRef, locationPayload, clusters, selectedRowIndex, deleteRow]);



  const setCellValueInactive = React.useCallback((rowData, value) => {
    var cluster_inactive = value;
    var editingRow = locationPayload.clusterList[selectedRowIndex];
    if (editingRow) {
      var internalid = editingRow.internalid ? editingRow.internalid : 0;
      var tipo = editingRow.clusterType ? editingRow.clusterType : null;
      var duplicate = locationPayload.clusterList.find(cluster => (parseInt(cluster.internalid) !== parseInt(internalid) && cluster.clusterType === tipo && cluster.isinactive === false));

      if (duplicate && cluster_inactive === false) {
        rowData.isinactive = true;
        setVisibleToast(true);
        setMessageToast("Otro cluster del mismo tipo está activo. No es posible activarlo.");
        setTypeToast('warning')

        if (gridClustersRef.current) {
          if (selectedRowIndex >= 0) {
            gridClustersRef.current.instance.cancelEditData();
          }
        }
      }
      else {
        rowData.isinactive = cluster_inactive;
      }
    }
    else {
      rowData.isinactive = cluster_inactive;
    }
  }, [gridClustersRef, locationPayload, selectedRowIndex,]);

  function onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Datos');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Datos.xlsx');
      });
    });
    e.cancel = true;
  };
  
  return (
    <div>
      {isDetail === false ? (
        <div className="bg-gray-200 m-2">
          <h1 className="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faList} size="lg" /> Locations</h1>
        </div>
      ) :
        (
          <div className="bg-gray-200 m-2">
            <h1 className="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1 pl-3"><FontAwesomeIcon icon={faLocationDot} size="xl" /> {locationPayload.name}</h1>
          </div>
        )}

      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        visible={visibleLoadPanel}
        showIndicator={true}
        message="Por favor espere..."
        shading={true}
        showPane={true}
        hideOnOutsideClick={false}
      />
      <Toast
        visible={visibleToast}
        message={messageToast}
        type={typeToast}
        onHiding={onHidingToast}
        displayTime={5000}
        width={'auto'}
        position={toastPosition}
      />
      <div className="bg-gray-200 m-2">
        {isDetail === false ? (
          <div className="bg-white p-3 shadow-xl">
            <div className="options mb-2">
              <div className="option">
                <CheckBox text="Expandir todos los grupos"
                  value={autoExpandAll}
                  onValueChanged={onAutoExpandAllChanged}>
                </CheckBox>
              </div>
            </div>
            <DataGrid
              id="gridLocationContainer"
              columnsAutoWidth={true}
              filterBuilder={filterBuilder}
              dataSource={locationRecords}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnResizingMode="widget"
              showBorders={true}
              rowAlternationEnabled={true}
              showRowLines={true}
              showColumnLines={true}
              onExporting={onExporting}
            >
              <Scrolling rowRenderingMode='virtual'></Scrolling>
              <Paging defaultPageSize={10} />
              <Pager
                visible={true}
                allowedPageSizes={allowedPageSizes}
                displayMode='compact'
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true} />
              <FilterRow visible={true} />
              <FilterPanel visible={true} />
              <FilterBuilderPopup position={filterBuilderPopupPosition} />

              <HeaderFilter visible={true} />

              <GroupPanel visible={true} />
              <SearchPanel visible={true} />
              <ColumnChooser
                enabled={true}
                mode='select'
              >
                <ColumnChooserSearch
                  enabled={true}
                />

                <ColumnChooserSelection
                  allowSelectAll={true}
                  selectByClick={true}
                  recursive={true}
                />
              </ColumnChooser>

              <Grouping autoExpandAll={autoExpandAll} />
              <Export enabled={true} />
              <StateStoring enabled={true} type="localStorage" storageKey="gridContainerListLocations" />

              <Column caption="" alignment="center" width={100} cellRender={editLocation} />

              <Column dataField="parent" caption="Ubicación Padre" dataType="string" width={250} groupIndex={0} >
                <HeaderFilter>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              <Column dataField="name" caption="Ubicación" dataType="string" width={250} >
                <HeaderFilter>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              <Column dataField="subsidiary" caption="Subsidiaria" dataType="string" width={250} >
                <HeaderFilter>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              <Column dataField="custrecord_vm_tipo_ubicacion" caption="Tipo de Ubicación" dataType="string" width={250} >
                <HeaderFilter>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              <Column dataField="city" caption="Ciudad" dataType="string" width={200} visible={false} >
                <HeaderFilter>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              <Column dataField="internalid" caption="internalid" dataType="number" width={200} visible={false} >
                <HeaderFilter>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              <Column dataField="custrecordcustrecord_vm_nombrecorto" caption="Nombre Corto" dataType="string" width={200} visible={true} >
                <HeaderFilter>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              <Column dataField="custrecord_gv_rlx_idrelex" caption="ID Ubicación Relex" dataType="string" width={200} visible={true} >
                <HeaderFilter>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              <Column dataField="custrecord_al_rlx_safety_lead_time" caption="Relex Safety Lead Time" dataType="number" width={200} visible={false} >
                <HeaderFilter>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              <Column dataField="custrecord_al_rlx_closing_date" caption="Relex Closing Date" dataType="date" width={200} visible={false} >
                <HeaderFilter>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              <Column dataField="custrecord_al_rlx_opening_date" caption="Relex Opening Date" dataType="date" width={200} visible={false} >
                <HeaderFilter>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              <Column dataField="custrecord_al_rlx_location_type" caption="Relex Location Type" dataType="string" width={200} visible={false} >
                <HeaderFilter>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              <Column dataField="custrecord_gv_rlx_tmklocation" caption="Telemarketing Location" dataType="string" width={200} visible={false} >
                <HeaderFilter>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              <Column dataField="custrecord_gv_rlx_cdclocation" caption="Consumption Center Location" dataType="string" width={200} visible={false} >
                <HeaderFilter>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              <Column dataField="custrecord_gv_rlx_receiptlocation" caption="Receipt Location" dataType="string" width={200} visible={false} >
                <HeaderFilter>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              <Column dataField="custrecord_gv_rlx_tmklocation_id" caption="Telemarketing Location ID" dataType="string" width={200} visible={false} >
                <HeaderFilter>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              <Column dataField="custrecord_gv_rlx_cdclocation_id" caption="Consumption Center Location ID" dataType="string" width={200} visible={false} >
                <HeaderFilter>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              <Column dataField="custrecord_gv_rlx_receiptlocation_id" caption="Receipt Location ID" dataType="string" width={200} visible={false} >
                <HeaderFilter>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
            </DataGrid>
          </div>
        ) : (<div />)}
        <Route exact path="/Locations/:locationId" render={({ match }) => {
          return (
            <div className="m-3 bg-gray-200">
              {isDetail.toString() === 'true' ?
                (
                  <div>
                    <Link to="/Locations">
                      <button onClick={cancelEditButton} type="submit" className="mb-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                        <FontAwesomeIcon icon={faArrowRotateLeft} /> Regresar
                      </button>
                    </Link>
                    {activateSaveButton.toString() === 'true' ?
                      (
                        <button onClick={saveButton} type="submit" className="ml-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                          <FontAwesomeIcon icon={faSave} /> Guardar Cambios
                        </button>
                      ) : (
                        <div className="inline-block"></div>
                      )}

                    <div className="mt-1 bg-white pt-3 shadow-xl">
                      <div className="ml-4 mr-4 bg-white pt-3">
                        <Form
                          id="datosGenerales"
                          labelMode={'outside'}
                          formData={locationPayload}

                          showColonAfterLabel={false}
                          labelLocation={'left'}
                          colCount={2}
                          ref={formDatosGeneralesRef}
                          onFieldDataChanged={onFormFieldChanged}
                        >
                          <GroupItem caption="Datos Generales">
                            <Item dataField="internalid" editorOptions={internalidEditorOptions} >
                              <Label text="internalid" visible={false} />
                            </Item>
                            <Item dataField="custrecord_vm_tipo_ubicacion" editorOptions={tipoUbicacionEditorOptions} >
                              <Label text="Tipo de Ubicación" />
                            </Item>
                            <Item dataField="custrecordcustrecord_vm_nombrecorto" editorOptions={nombreCortoEditorOptions} >
                              <Label text="Nombre Corto" />
                            </Item>
                            <Item dataField="parent" editorOptions={parentEditorOptions} >
                              <Label text="Ubicación Padre" />
                            </Item>
                            <Item dataField="subsidiary" editorOptions={subsidiaryEditorOptions} >
                              <Label text="Subsidiaria" />
                            </Item>
                          </GroupItem>
                          <GroupItem caption="Datos Relex" colCount={2}>
                            <Item dataField="custrecord_al_rlx_location_type" editorOptions={relexLocationTypeEditorOptions} >
                              <Label text="Location Type" />
                            </Item>
                            <Item dataField="custrecord_al_rlx_opening_date" editorType="dxDateBox" editorOptions={relexOpeningDateEditorOptions} >
                              <Label text="Opening Date" />
                            </Item>
                            <Item dataField="custrecord_al_rlx_closing_date" editorType="dxDateBox" editorOptions={relexClosingDateEditorOptions} >
                              <Label text="Closing Date" />
                            </Item>
                            <Item dataField="custrecord_al_rlx_safety_lead_time" editorOptions={relexSafetyLeadTimeEditorOptions} >
                              <Label text="Safety Lead Time" />
                            </Item>
                            <Item dataField="custrecord_gv_rlx_cdclocation" editorOptions={relexCDCEditorOptions} >
                              <Label text="Consumption Center Location" />
                            </Item>
                            <Item dataField="custrecord_gv_rlx_cdclocation_id" editorOptions={relexCDCIDEditorOptions} >
                              <Label text="Consumption Center Location ID" />
                            </Item>
                            <Item dataField="custrecord_gv_rlx_idrelex" editorOptions={relexIDEditorOptions} >
                              <Label text="ID Ubicación" />
                            </Item>
                            <Item dataField="custrecord_gv_rlx_receiptlocation" editorOptions={relexReceiptLocationEditorOptions} >
                              <Label text="Receipt Location" />
                            </Item>
                            <Item dataField="custrecord_gv_rlx_receiptlocation_id" editorOptions={relexReceiptLocationIDEditorOptions} >
                              <Label text="Receipt Location ID" />
                            </Item>
                            <Item dataField="custrecord_gv_rlx_tmklocation" editorOptions={relexTMKLocationEditorOptions} >
                              <Label text="Telemarketing Location" />
                            </Item>
                            <Item dataField="custrecord_gv_rlx_tmklocation_id" editorOptions={relexTMKIDLocationEditorOptions} >
                              <Label text="Telemarketing Location ID" />
                            </Item>

                          </GroupItem>


                          <GroupItem caption="Clusters" colSpan={2}>
                            <TabbedItem>
                              <TabPanelOptions deferRendering={false} showNavButtons={true} />
                              <Tab title="Clusters">
                                <DataGrid
                                  id="gridClusters"
                                  ref={gridClustersRef}
                                  columnsAutoWidth={true}
                                  dataSource={locationPayload.clusterList}
                                  showBorders={true}
                                  showColumnLines={true}
                                  showRowLines={true}
                                  rowAlternationEnabled={true}
                                  allowColumnResizing={true}
                                  columnResizingMode="widget"
                                  allowColumnReordering={false}
                                  repaintChangesOnly={true}

                                  onEditorPreparing={onEditorClustersPreparing}
                                  onCellClick={onCellClickClusters}
                                  onEditingStart={onCellEditingStart}
                                >
                                  <Scrolling
                                    mode='virtual'
                                  />
                                  <Editing
                                    mode="row"
                                    newRowPosition="last"
                                    allowUpdating={activateUpdateGrid}
                                    allowAdding={activateUpdateGrid}
                                    allowDeleting={false}
                                    confirmDelete={false}
                                    useIcons={true}
                                  />

                                  <Sorting mode="none" />
                                  <Column dataField="clusterName" dataType="string" width={100} visible={false} allowEditing={false} />
                                  <Column
                                    dataField="custrecord_al_portal_loc_cluster_clus"
                                    caption="Cluster"
                                    setCellValue={setCellValueCluster}
                                    width={300}
                                    cellTemplate={(cellElement, cellInfo) => {
                                      const { data } = cellInfo;
                                      if (data.internalid) {
                                        cellElement.innerHTML = `🔒 ${data.clusterName}`;
                                        cellElement.classList.add('locked-cell');
                                      } else {
                                        cellElement.innerHTML = data.clusterName;
                                        cellElement.classList.remove('locked-cell');
                                      }
                                    }}
                                  >
                                    <Lookup
                                      dataSource={clusters}
                                      valueExpr="value"
                                      displayExpr={(data) => `${data.tipo}: ${data.name}`}
                                    />
                                    <RequiredRule message="Cluster is required" />
                                  </Column>
                                  <Column dataField="clusterType" caption="Tipo de Cluster" dataType="string" width={150} visible={true} allowEditing={false} />
                                  <Column
                                    width={100}
                                    dataField="isinactive"
                                    caption="Inactivo"
                                    setCellValue={setCellValueInactive}
                                    dataType="boolean"
                                    cellTemplate={(cellElement, cellInfo) => {
                                      const { data } = cellInfo;
                                      const checkboxSymbol = data.isinactive ? "🚫" : " ";
                                      cellElement.innerHTML = checkboxSymbol;
                                    }}
                                  />

                                  <Column width={100} cellRender={(cellData) =>
                                    !cellData.row.data.internalid ? ( // put your condition here
                                      <button
                                        style={{ color: "darkred", marginLeft: '5px', textDecoration: 'underline' }}

                                        onClick={(event) => {
                                          event.preventDefault();
                                          deleteRow();
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faTrash} />  Eliminar
                                      </button>
                                    ) : null
                                  } />
                                </DataGrid>
                              </Tab>
                              <Tab title="Product-Location">
                                <GroupItem caption="Filtros" colCount={4}>
                                  <Item colSpan={4} >
                                    <button onClick={refreshButton} type="submit" className="mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                      <FontAwesomeIcon icon={faArrowsRotate} /> Actualizar
                                    </button></Item>
                                  <Item dataField="custitem_al_ecommerce_departamento" editorType="dxSelectBox" editorOptions={departamentoEditorOptions} colSpan={2}  >
                                    <Label text={"Departamento"} />
                                  </Item>
                                  <Item dataField="custitem_al_ecommerce_categoria" editorType="dxSelectBox" editorOptions={categoriaEditorOptions} colSpan={2} >
                                    <Label text={"Categoría"} />
                                  </Item>
                                  <Item dataField="custitem_efx_cmp_exclusivo" editorType="dxSelectBox" editorOptions={exclusivoEditorOptions} colSpan={2} >
                                    <Label text={"Exclusivo"} />
                                  </Item>
                                  <Item dataField="vendor" editorType="dxSelectBox" editorOptions={vendorEditorOptions} colSpan={2}  >
                                    <Label text={"Proveedor"} />
                                  </Item>

                                  <Item badge="First" colSpan={4}   >
                                    <DataGrid
                                      id="gridProductLocation"
                                      ref={gridProductLocationRef}
                                      columnsAutoWidth={true}
                                      dataSource={productsFromLocationList}
                                      showBorders={true}
                                      showColumnLines={true}
                                      showRowLines={true}
                                      rowAlternationEnabled={true}
                                      allowColumnResizing={true}
                                      columnResizingMode="widget"
                                      allowColumnReordering={true}
                                      repaintChangesOnly={true}
                                      filterBuilder={filterBuilder}
                                      onExporting={onExporting}
                                    >
                                      <Scrolling mode='virtual' />
                                      <Paging defaultPageSize={50} />

                                      <Pager
                                        visible={true}
                                        allowedPageSizes={allowedPageSizes}
                                        displayMode='compact'
                                        showPageSizeSelector={true}
                                        showInfo={true}
                                        showNavigationButtons={true} />
                                      <FilterRow visible={true} />
                                      <FilterPanel visible={true} />
                                      <FilterBuilderPopup position={filterBuilderPopupPosition} />

                                      <HeaderFilter visible={true} />

                                      <GroupPanel visible={true} />
                                      <SearchPanel visible={true} />
                                      <ColumnChooser
                                        enabled={true}
                                        mode='select'
                                      >
                                        <ColumnChooserSearch
                                          enabled={true}
                                        />

                                        <ColumnChooserSelection
                                          allowSelectAll={true}
                                          selectByClick={true}
                                          recursive={true}
                                        />
                                      </ColumnChooser>
                                      <ColumnFixing enabled={true} />
                                      <Grouping autoExpandAll={autoExpandAll} />
                                      <Export enabled={true} />

                                      <StateStoring enabled={true} type="localStorage" storageKey="gridContainerProductLocation" />

                                      <Editing
                                        mode="cell"
                                        newRowPosition="last"
                                        allowUpdating={false}
                                        allowAdding={false}
                                        allowDeleting={false}
                                      />

                                      <Column dataField="isinactive" caption="Inactivo" allowEditing={false} dataType="boolean" width={120} >
                                        <HeaderFilter>
                                          <Search enabled={true} />
                                        </HeaderFilter>
                                      </Column>
                                      <Column dataField="itemid" caption="Nombre/Número del Artículo" allowEditing={false} dataType="string" width={250} >
                                        <HeaderFilter>
                                          <Search enabled={true} />
                                        </HeaderFilter>
                                      </Column>
                                      <Column dataField="displayname" caption="Nombre/Código para mostrar" allowEditing={false} dataType="string" width={400} >
                                        <HeaderFilter>
                                          <Search enabled={true} />
                                        </HeaderFilter>
                                      </Column>
                                      <Column dataField="custitem_gv_rlx_startsupplydate" caption="Fecha Inicio Abastecimiento" dataType="date" allowEditing={false} width={220} visible={true} >
                                        <HeaderFilter>
                                          <Search enabled={true} />
                                        </HeaderFilter>
                                      </Column>
                                      <Column dataField="custitem_gv_rlx_endsupplydate" caption="Fecha Fin Abastecimiento" dataType="date" allowEditing={false} width={220} visible={true} >
                                        <HeaderFilter>
                                          <Search enabled={true} />
                                        </HeaderFilter>
                                      </Column>
                                      <Column dataField="custitem_al_ecommerce_categoria" caption="eCommerce Categoría" dataType="string" width={300} visible={false} >
                                        <HeaderFilter>
                                          <Search enabled={true} />
                                        </HeaderFilter>
                                      </Column>
                                      <Column dataField="custitem_al_ecommerce_departamento" caption="eCommerce Departamento" dataType="string" width={300} visible={false} >
                                        <HeaderFilter>
                                          <Search enabled={true} />
                                        </HeaderFilter>
                                      </Column>
                                      <Column dataField="custitem_al_ecommerce_familia" caption="eCommerce Familia" dataType="string" width={300} visible={false} >
                                        <HeaderFilter>
                                          <Search enabled={true} />
                                        </HeaderFilter>
                                      </Column>
                                      <Column dataField="custitem_al_ecommerce_marca" caption="eCommerce Marca" dataType="string" width={300} visible={false} >
                                        <HeaderFilter>
                                          <Search enabled={true} />
                                        </HeaderFilter>
                                      </Column>
                                      <Column dataField="custitem_al_ecommerce_pais" caption="eCommerce País" dataType="string" width={300} visible={false} >
                                        <HeaderFilter>
                                          <Search enabled={true} />
                                        </HeaderFilter>
                                      </Column>
                                      <Column dataField="custitem_al_ecommerce_region" caption="eCommerce Región" dataType="string" width={300} visible={false} >
                                        <HeaderFilter>
                                          <Search enabled={true} />
                                        </HeaderFilter>
                                      </Column>
                                      <Column dataField="custitem_al_ecommerce_subfamilia" caption="eCommerce Subfamilia" dataType="string" width={300} visible={false} >
                                        <HeaderFilter>
                                          <Search enabled={true} />
                                        </HeaderFilter>
                                      </Column>
                                      <Column dataField="custitem_al_ecommerce_submarca" caption="eCommerce Submarca" dataType="string" width={300} visible={false} >
                                        <HeaderFilter>
                                          <Search enabled={true} />
                                        </HeaderFilter>
                                      </Column>
                                      <Column dataField="custitem_al_ecommerce_subregion" caption="eCommerce Subregión" dataType="string" width={300} visible={false} >
                                        <HeaderFilter>
                                          <Search enabled={true} />
                                        </HeaderFilter>
                                      </Column>
                                      <Column dataField="custitem_al_ecommerce_tipo_organico" caption="eCommerce Tipo Orgánico" dataType="string" width={300} visible={false} >
                                        <HeaderFilter>
                                          <Search enabled={true} />
                                        </HeaderFilter>
                                      </Column>
                                      <Column dataField="custitem_al_ecommerce_varietal" caption="eCommerce Varietal" dataType="string" width={300} visible={false} >
                                        <HeaderFilter>
                                          <Search enabled={true} />
                                        </HeaderFilter>
                                      </Column>

                                      <Column dataField="custitem_efx_cmp_exclusivo" caption="Exclusivo" dataType="string" width={300} visible={false} >
                                        <HeaderFilter>
                                          <Search enabled={true} />
                                        </HeaderFilter>
                                      </Column>
                                      <Column dataField="vendor" caption="Proveedor Principal" dataType="string" width={300} visible={false} >
                                        <HeaderFilter>
                                          <Search enabled={true} />
                                        </HeaderFilter>
                                      </Column>
                                    </DataGrid>
                                  </Item>
                                </GroupItem>
                              </Tab>
                            </TabbedItem>
                          </GroupItem>
                        </Form>
                      </div>
                    </div>
                  </div>
                ) :
                (<div></div>)
              }
            </div>
          );
        }}
        />
      </div>
    </div >
  );
}